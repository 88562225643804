import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { inject } from "mobx-react";
import colors from "../utils/colors";
import { withTranslation, WithTranslation } from "react-i18next";
import { handleAsyncErrors } from "../utils/api";
import { ApplicationStore } from "../stores/application";
import backgroundImage from "../assets/full.jpg";
import logo from "../assets/fmg-logo.svg";
import Button from "@material-ui/core/Button";
import { Snackbar } from "@material-ui/core";
import { LoginResult } from "../types/api";

const MainContainer = styled.div`
  height: 100%;
`;

interface BackgroundProps {
  backgroundUrl: string;
}

const Content = styled.div<BackgroundProps>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("${(props) => props.backgroundUrl}"), url(${backgroundImage});
  backgroundposition: center center;
  backgroundrepeat: no-repeat;
  backgroundattachment: fixed;
  backgroundsize: cover;
`;

const PasswordField = styled.input`
  height: 30px;
  width: 200px;
  padding: 4px;
  border: 1px solid ${colors.GRAY};
  &:focus {
    outline: none;
  }
  color: ${colors.DARK_GRAY};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 16px;
  min-width: 200px;
`;

const EmailField = styled.input`
  height: 30px;
  width: 200px;
  padding: 4px;
  border: 1px solid ${colors.GRAY};
  &:focus {
    outline: none;
  }
  color: ${colors.DARK_GRAY};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 16px;
  min-width: 200px;
`;

const LoginForm = styled.form`
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  height: 100%;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  grid-column: 2 / span 2;
`;

const LoginContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  width: 40%;
  background-color: #fff;
  padding: 2rem 1rem;
  min-width: 300px;
  max-width: 800px;
  min-height: 150px;
  @media (max-width: 375px) {
    padding: 2rem 0rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 100%;
  height: 50px;
`;

enum LoginScheme {
  PasswordOnly,
  EmailOnly,
  EmailnAndPassword,
}

interface IState {
  password: string;
  email: string;
  snackbarMessage: string;
  loginScheme: LoginScheme;
  triedLoggingAtLeastOnce: boolean;
}

type IProps = RouteComponentProps &
  WithTranslation & {
    applicationStore?: ApplicationStore;
  };

@inject("applicationStore")
class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      password: "",
      email: "",
      snackbarMessage: "",
      loginScheme: LoginScheme.EmailnAndPassword,
      triedLoggingAtLeastOnce: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);

    if (this.props.applicationStore) {
      this.props.applicationStore
        .updatePasswordToken(this.props.location.search)
        .then(() =>
          this.props.applicationStore?.login().then((result) => this?.afterLoginAttempt(result)),
        );
    }
  }

  async afterLoginAttempt(loginResult: LoginResult) {
    if (loginResult === LoginResult.NewPassword || loginResult === LoginResult.LoggedIn) {
      this.setState({});
      this.props.history.push("/move/info");
      this.props.applicationStore?.setLoading(true);
      await this.props.applicationStore?.initializeLegacyFloorPlanStoreThings();
      return;
    } else {
      this.showMessage("Invalid login attempt");

      if (loginResult === LoginResult.ReloginNeededWithPassword) {
        this.changeLoginScheme(LoginScheme.PasswordOnly);
      } else if (loginResult === LoginResult.ReloginNeededWithEmail) {
        this.changeLoginScheme(LoginScheme.EmailOnly);
      } else {
        this.changeLoginScheme(LoginScheme.EmailnAndPassword);
      }
    }
  }

  @handleAsyncErrors({ logErrorMessage: "Wrong password" })
  public async validatePassword() {
    if (this.props.applicationStore) {
      await this.props.applicationStore.setPassword(this.state.password);
      this.afterLoginAttempt(await this.props.applicationStore.login());
    }
  }

  @handleAsyncErrors({ logErrorMessage: "Invalid domain" })
  public async requestLoginLink() {
    if (this.props.applicationStore) {
      if (
        await this.props.applicationStore.requestLoginLink(this.state.email, this.state.password)
      ) {
        this.showMessage("Link sent");
      } else {
        this.showMessage("Couldn't obtain link");
      }
    }
  }

  changeLoginScheme(loginScheme: LoginScheme) {
    this.setState({ loginScheme: loginScheme, triedLoggingAtLeastOnce: true });
  }

  showMessage(message: string) {
    this.setState({
      snackbarMessage: message,
    });
  }

  onPasswordChange = (password: string) => {
    this.setState({
      password: password,
    });
  };

  onEmailChange = (email: string) => {
    this.setState({
      email: email,
    });
  };

  onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (this.state.loginScheme === LoginScheme.PasswordOnly) {
      await this.validatePassword();
    } else {
      await this.requestLoginLink();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <MainContainer>
        <Snackbar
          message={this.state.snackbarMessage}
          open={this.state.snackbarMessage !== ""}
          autoHideDuration={3000}
          onClose={() => {
            this.showMessage("");
          }}
        ></Snackbar>
        <Content backgroundUrl={this.props.applicationStore!.backgroundUrl}>
          <LoginContainer>
            <LogoContainer>
              <LogoImage src={logo} />
            </LogoContainer>
            <LoginForm onSubmit={this.onSubmit}>
              {this.state.triedLoggingAtLeastOnce &&
                this.state.loginScheme !== LoginScheme.PasswordOnly && (
                  <div>
                    <EmailField
                      type="email"
                      value={this.state.email}
                      onChange={(e) => this.onEmailChange(e.target.value)}
                      placeholder="email"
                      autoFocus={true}
                    />
                  </div>
                )}
              {this.state.triedLoggingAtLeastOnce &&
                this.state.loginScheme !== LoginScheme.EmailOnly && (
                  <div>
                    <PasswordField
                      type="text"
                      value={this.state.password}
                      onChange={(e) => this.onPasswordChange(e.target.value)}
                      placeholder="password"
                      autoFocus={this.state.loginScheme === LoginScheme.PasswordOnly}
                    />
                  </div>
                )}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ width: "200px" }}
                >
                  {this.state.loginScheme === LoginScheme.PasswordOnly && t("Sign in")}
                  {this.state.loginScheme !== LoginScheme.PasswordOnly && t("Send login link")}
                </Button>
              </div>
            </LoginForm>
          </LoginContainer>
        </Content>
      </MainContainer>
    );
  }
}

export default withTranslation()(withRouter(Login));
