import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import EmployeeUiLayout from "../../../components-mobile/EmployeeUiLayout";
import ReturnButton from "../../../components-mobile/ReturnButton";
import { InjectedFloorPlanStore, FloorPlanStore } from "../../../stores/floorplan";
import { RouteComponentProps } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";
import { onDesktop } from "../../../utils/breakpoint";
import { Title } from "../../../components/BrandText";
import { ArticleEditorWrapper } from "../../../lib/article-editor/ArticleEditorWrapper";
import { ApplicationStore } from "../../../stores/application";

const DocumentTitle = styled(Title)`
  ${onDesktop} {
    display: none;
  }
`;

type IProps = RouteComponentProps<any> &
  WithTranslation & {
    floorPlanStore: FloorPlanStore;
    applicationStore: ApplicationStore;
  };

@inject("floorPlanStore")
@inject("applicationStore")
@observer
class Faq extends Component<IProps> {
  get injected() {
    return this.props as InjectedFloorPlanStore;
  }

  componentDidMount() {
    const docId = this.props.match.params.docId;
    if (docId) {
      this.injected.floorPlanStore.setDocumentId(docId);
    }
  }

  render() {
    const { t } = this.props;
    const { title, content } = this.injected.floorPlanStore.document || { title: "", content: "" };
    const { headingColor } = this.props.applicationStore;
    return (
      <EmployeeUiLayout backgroundStyle="slim" title={title}>
        <ReturnButton
          customColor={headingColor}
          onClick={() => this.props.history.push("/move/info")}
          title={t("info.title")}
        />
        <DocumentTitle style={{ color: headingColor }}>{title}</DocumentTitle>
        <ArticleEditorWrapper articleHtml={content} />
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(Faq);
