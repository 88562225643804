import React from "react";
import { observer, inject } from "mobx-react";
import { InjectedFloorPlanStore, FloorPlanStore } from "../stores/floorplan";
import { RouteComponentProps } from "react-router";
import { get } from "lodash";

type IProps = RouteComponentProps<any> & {
  floorPlanStore?: FloorPlanStore;
};

@inject("floorPlanStore")
@observer
class SyncFloorNumberFromUrlToStore extends React.Component<IProps> {
  get injected() {
    return {
      floorPlanStore: this.props.floorPlanStore,
    } as InjectedFloorPlanStore;
  }

  onRouteChanged() {
    const floorIdStr = get(this, "props.match.params.floorIdStr");
    const { setCurrentFloorBasedOnUrlParam } = this.injected.floorPlanStore;
    if (floorIdStr) {
      setCurrentFloorBasedOnUrlParam(floorIdStr);
    }
  }

  componentDidMount() {
    this.onRouteChanged();
  }

  componentDidUpdate() {
    this.onRouteChanged();
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default SyncFloorNumberFromUrlToStore;
