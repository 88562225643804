import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ApplicationStore, InjectedApplicationStore } from "../stores/application";
import ContactButton from "./ContactButton";
type IState = {};
type IProps = WithTranslation & {
  appearance: "image" | "text";
  backText?: string;
  backTo?: string;
  applicationStore?: ApplicationStore;
};
@inject("applicationStore")
@observer
class ContactButtons extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  get injected() {
    return this.props as InjectedApplicationStore;
  }
  render() {
    const { secondaryColor, darkerSecondaryColor } = this.injected.applicationStore;
    const { backText, backTo, appearance, t } = this.props;
    let searchParams = "";
    if (backText && backTo) {
      searchParams = `?rt=${encodeURIComponent(backText)}&rp=${encodeURIComponent(backTo)}`;
    }
    return (
      <>
        <ContactButton
          customSecondaryColor={secondaryColor}
          customDarkerSecondaryColor={darkerSecondaryColor}
          column={1}
          span={2}
          title={t("contact.actions.question")}
          image={secondaryColor ? "question-black.jpeg" : "question.jpeg"}
          route={`/move/contact/ask${searchParams}`}
          appearance={appearance}
        />
        <ContactButton
          customSecondaryColor={secondaryColor}
          customDarkerSecondaryColor={darkerSecondaryColor}
          column={1}
          span={1}
          title={t("contact.actions.error")}
          image={secondaryColor ? "error-black.jpeg" : "error.jpeg"}
          route={`/move/contact/error-report${searchParams}`}
          appearance={appearance}
        />
        <ContactButton
          customSecondaryColor={secondaryColor}
          customDarkerSecondaryColor={darkerSecondaryColor}
          column={2}
          span={1}
          title={t("contact.actions.feedback")}
          image={secondaryColor ? "feedback-black.jpeg" : "feedback.jpeg"}
          route={`/move/contact/feedback${searchParams}`}
          appearance={appearance}
        />
      </>
    );
  }
}

export default withTranslation()(ContactButtons);
