import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Snackbar } from "@material-ui/core";
import EmployeeUiLayout from "../../../components-mobile/EmployeeUiLayout";
import ReturnButton from "../../../components-mobile/ReturnButton";
import { BrandButtonPrimary, BrandButtonNegative } from "../../../components/BrandButton";
import { FloorPlanStore } from "../../../stores/floorplan";
import { FeedbackType } from "../../../types/api";
import {
  FormField,
  FormLabel,
  FormTextField,
  QuestionField,
  BtnContainer,
  Description,
} from "./QuestionForm";
import { RouteComponentProps } from "react-router";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { Title } from "../../../components/BrandText";
import { ApplicationStore } from "../../../stores/application";

/*
  PLEASE NOTE!
  All styled components are defined in ./QuestionForm.tsx for consistency
*/

type IProps = RouteComponentProps<any> &
  WithTranslation & {
    floorPlanStore: FloorPlanStore;
    applicationStore: ApplicationStore;
  };

interface IState {
  name: string;
  email: string;
  number: string;
  question: string;
  snackbarMessage: string;
}

@inject("floorPlanStore")
@inject("applicationStore")
@observer
class ErrorForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: "",
      email: "",
      number: "",
      question: "",
      snackbarMessage: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = () => {
    const t = this.props.t;
    this.props.floorPlanStore.sendFeedback({
      name: this.state.name,
      email: this.state.email,
      phone: this.state.number,
      feedback_type: FeedbackType.Error,
      question: this.state.question,
    }).then(result => {
      this.setState({
        name: "",
        email: "",
        number: "",
        question: "",
      });
      this.showMessage(t("feedback_form.sent"));
    }, error => {
      this.showMessage(t("feedback_form.didntsend"));
    });
  };

  showMessage(message: string) {
    this.setState({
      snackbarMessage: message,
    });
  };

  render() {
    const { t } = this.props;
    const { headingColor } = this.props.applicationStore;
    return (
      <EmployeeUiLayout>
        <Snackbar
          message={this.state.snackbarMessage}
          open={this.state.snackbarMessage !== ""}
          autoHideDuration={3000}
          onClose={() => {
            this.showMessage("");
          }}
        ></Snackbar>
        <ReturnButton
          customColor={headingColor}
          history={this.props.history}
          to="/move/contact"
          title={t("contact.return_button_link")}
        />
        <Title style={{ color: headingColor }}>{t("error_form.title")}</Title>
        <Description>
          <Trans i18nKey="error_form.description" />
        </Description>
        <FormField>
          <FormLabel>{t("form.name.label")}</FormLabel>
          <FormTextField
            placeholder={t("form.name.placeholder")}
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t("form.email.label")}</FormLabel>
          <FormTextField
            placeholder={t("form.email.placeholder")}
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t("form.phone.label")}</FormLabel>
          <FormTextField
            placeholder={t("form.phone.placeholder")}
            value={this.state.number}
            onChange={(e) => this.setState({ number: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t("error_form.error.label")} *</FormLabel>
          <QuestionField
            rows={5}
            placeholder={t("error_form.error.placeholder")}
            value={this.state.question}
            onChange={(e) => this.setState({ question: e.target.value })}
          />
        </FormField>

        <BtnContainer>
          <BrandButtonNegative
            onClick={() => {
              this.setState({
                name: "",
                email: "",
                number: "",
                question: "",
              });
              this.props.history.push("/move/contact");
            }}
          >
            {t("buttons.cancel")}
          </BrandButtonNegative>
          <BrandButtonPrimary onClick={() => this.onSubmit()} disabled={this.state.question === ""}>
            {t("buttons.send")}
          </BrandButtonPrimary>
        </BtnContainer>
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(ErrorForm);
