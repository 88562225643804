import React from "react";
import styled from "styled-components";
import { FiSearch } from "react-icons/fi";
import colors from "../utils/colors";
import constants from "../utils/constants";
import { RouteComponentProps } from "react-router";
import { observer, inject } from "mobx-react";
import { FloorPlanStore } from "../stores/floorplan";
import { throttle } from "lodash";
import { PeopleStore } from "../stores/people";
import { withTranslation, WithTranslation } from "react-i18next";
import { onDesktop } from "../utils/breakpoint";

type ContainerProps = {
  hide?: boolean;
};
const Container = styled.div<ContainerProps>`
  position: fixed;
  top: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
  left: 0;

  right: 0;
  ${onDesktop} {
    right: auto;
    width: calc(${constants.SIDEBAR_WIDTH} - 2 * ${constants.SEARCHBAR_MARGIN});
  }

  border: 1px solid ${colors.TURQUOISE};
  margin: ${constants.SEARCHBAR_MARGIN};
  display: flex;
  align-items: center;
  background-color: ${colors.WHITE};
  z-index: 100;
  opacity: ${(p: ContainerProps) => (p.hide ? 0 : 100)};
  &:hover {
    opacity: 100;
  }
`;

const InputField = styled.input`
  width: calc(100% - 16px - (2 * 12px));
  height: ${constants.SEARCHBAR_HEIGHT};
  line-height: ${constants.SEARCHBAR_HEIGHT};
  padding: 4px 0 4px 12px;
  border: 0;
  flex: 2;
  color: ${colors.DARK_GRAY};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
  &::placeholder {
    color: ${colors.GRAY};
    font-size: 12px;
    letter-spacing: 0.36px;
  }
  &:focus {
    outline: none;
  }
`;

const SearchIcon = styled(FiSearch)`
  padding: 4px 12px 0px 12px;
`;

const routeFor = (floor: string, searchString: string) =>
  `/move/floor/${floor}/search/${searchString}`;
const searchRouteRegExp = /^\/move\/floor\/[\d]+\/search/;

type IProps = RouteComponentProps<any> &
  WithTranslation & {
    floorPlanStore?: FloorPlanStore;
    peopleStore?: PeopleStore;
    forceClear: boolean;
    hide?: boolean;
  };

type IState = {
  searchString: string;
};

@inject("floorPlanStore")
@inject("peopleStore")
@observer
class SearchBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchString: "",
    };
  }

  search(searchString: string) {
    this.setState({ searchString });
    if (this.props.peopleStore) {
      this.props.peopleStore.search(searchString);
    }
  }

  componentDidMount() {
    this.search(this.props.match.params.searchString);
  }

  static getDerivedStateFromProps(nextProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (nextProps.forceClear) {
      return {
        searchString: "",
      };
    }
    return null;
  }

  onSubmit(e: any) {
    /*
      Clicking "search" button on mobile browser causes the submit event to
      tricker. When we actually search _while_ typing, all we need to do is to
      prevent the submit event and to hide the virtual keyboard
    */
    e.preventDefault();
    // Hides the virtual keyboard, adapted from https://stackoverflow.com/a/7761438
    if (document.activeElement) {
      (document.activeElement as HTMLInputElement).blur();
    }
  }

  render() {
    const { t, floorPlanStore, peopleStore, hide } = this.props;
    const { searchString } = this.state;

    if (!floorPlanStore || !peopleStore) {
      return;
    }

    const isOnSearchRoute = this.props.location.pathname.match(searchRouteRegExp) !== null;

    const pushPath = this.props.history.push; // No throttling needed, this happens only once
    const replacePathImmeadiately = this.props.history.replace;
    // Throttling is used, so that updating route (and thus triggering some rendering actions)
    // wouldn't degrade the UX of typing into the input field
    const replacePathSoon = throttle(this.props.history.replace, 500, {
      leading: false,
      trailing: true,
    });

    const onSearchStringChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!floorPlanStore.currentFloor) {
        return;
      }
      const replaceAction = e.target.value.length > 0 ? replacePathSoon : replacePathImmeadiately;
      const routeAction = isOnSearchRoute ? replaceAction : pushPath;
      routeAction(routeFor(floorPlanStore.currentFloor.id, e.target.value));
      this.search(e.target.value);
    };

    return (
      <Container hide={hide}>
        <form action="." onSubmit={this.onSubmit} style={{ width: "100%" }}>
          <InputField
            type="search"
            placeholder={t("search.search_bar.placeholder")}
            onChange={onSearchStringChanged}
            value={searchString || ""}
          />
          <SearchIcon size={16} color={colors.TURQUOISE} />
        </form>
      </Container>
    );
  }
}

export default withTranslation()(SearchBar);
