import { action, observable, computed, runInAction, reaction } from "mobx";
import { isNil, flatten, defer } from "lodash";
import constants from "../utils/constants";
import {
  Move,
  FloorPlanEditState,
  Floor,
  UserType,
  SpaceTag,
  TableTag,
  Owner,
  isAssignedOwner,
  isAssignedTo,
  FloorSize,
} from "../types/floorplan";
import {
  getSpaceByTag,
  getTableByTag,
  getSpaceByTable,
  getTableByLetter,
} from "../utils/mark-utils";
import API from "../utils/api";
import {
  FloorMarker,
  ContactInfo,
  GeneralInfo,
  FAQElement,
  Feedback,
  UploadedDocument,
} from "../types/api";
import { FAQuestion, PageDocumentWithTranslations, PageDocument } from "../types/move";
import StoresComponent from "./StoresComponent";
import {
  SpaceTagToMarker,
  TableTagToMarker,
  parseLayout,
  parseAssigns,
  moveToWritableMove,
} from "../utils/typeConversions";
import { ApplicationMode } from "./application";
import i18n from "../i18n";

export interface InjectedFloorPlanStore {
  floorPlanStore: FloorPlanStore;
}

export class FloorPlanStore {
  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
    defer(this.initializeSideEffects);
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  @observable
  public userType: UserType = UserType.RPM;

  @observable
  public editState: FloorPlanEditState = FloorPlanEditState.Upload;

  @observable
  public move: Move | undefined;

  @observable
  public floors: Floor[] = [];

  @observable
  public currentFloor: Floor | null = null;

  @observable
  public owners: Owner[] = [];

  // Currently selected space for connecting owners.
  @observable
  public spaceSelection: SpaceTag | null = null;

  // TODO: Correct types missing:

  @observable
  public moveKeyInfo: GeneralInfo[] = [];

  @observable
  public contactInfo: ContactInfo[] = [];

  @observable faq: FAQElement[] = [];

  @observable faQuestions: FAQuestion[] = [];

  @observable
  public documents = new Map<string, PageDocumentWithTranslations>();

  @observable
  public uploadedDocuments: UploadedDocument[] = [];

  @observable
  public documentId = "";

  @observable
  public hideFloorSelection = false;

  // ----------------COMPUTED----------------

  @computed
  get companyName() {
    return this.move ? this.move.company.name : undefined;
  }

  @computed // @todo: Legacy support; remove this when possible
  get backendPassword() {
    return this.stores.applicationStore.password;
  }

  @computed // @todo: Legacy support; remove this when possible
  get APISubdomain() {
    return this.stores.applicationStore.subdomain;
  }

  // Returns all saved table markers belonging to current floor.
  @computed
  get tableTags(): TableTag[] {
    if (isNil(this.currentFloor)) {
      return [];
    }
    const tags: TableTag[] = [];

    this.currentFloor.spaces.forEach((space) => {
      space.tables.forEach((tag) => {
        tags.push(tag);
      });
    });
    return tags;
  }

  @computed
  get assignedOwners(): Owner[] {
    return this.owners.filter(isAssignedOwner);
  }

  @computed
  get unAssignedOwners(): Owner[] {
    return this.owners.filter((o) => !isAssignedOwner(o));
  }

  @computed
  get spaceAssignedOwners(): Owner[] {
    return this.owners.filter((o) => this.spaceSelection && isAssignedTo(o, this!.spaceSelection));
  }

  @computed
  get document(): PageDocument | null {
    const document = this.documents.get(this.documentId);
    if (!document) return null;
    const translation =
      document.translations.find((t) => t.locale === this.stores.localesStore.locale.backend) ||
      document.translations[0];
    if (!translation) return null;
    return {
      id: this.documentId,
      locale: translation.locale,
      title: translation.title,
      content: translation.content,
    };
  }

  @computed
  get moveLogoUrl() {
    return this.move ? this.move.logoUrl : "";
  }

  // ---------AUTOMATIC SIDE-EFFECTS--------

  private initializeSideEffects = () => {
    reaction(
      () => this.unAssignedOwners,
      (unAssignedOwners) => {
        if (this.unassignedPeopleAlreadyFetched) {
          API.saveUnassignedOwners(this.backendPassword, this.APISubdomain, this.unAssignedOwners);
        }
      },
    );
  };

  // ----------------ACTIONS----------------

  @action sendFeedback = async (question: Feedback) => {
    await API.postFeedback(this.backendPassword, this.APISubdomain, question);
    // TODO: Indicate user the response success/fail
  };

  @action
  public setDocumentId = (documentId: string) => {
    this.documentId = documentId;
    if (!this.document || !this.document.content) {
      this.fetchPage(documentId);
    }
  };

  @action
  public fetchPages = async () => {
    (await API.getAllPages(this.backendPassword, this.APISubdomain))
      .filter((page) => !this.documents.has(page.id))
      .forEach((page) => this.documents.set(page.id, page));
    return this.documents;
  };

  @action
  public fetchUploadedDocuments = async () => {
    this.uploadedDocuments = await API.getUploadedDocuments(
      this.backendPassword,
      this.APISubdomain,
    );
    return this.uploadedDocuments;
  };

  @action
  public downloadSingleDocument = async (documentId: string) => {
    return await API.downloadDocument(this.backendPassword, this.APISubdomain, documentId);
  };

  /*
    This method fetches data for only a single page. This API request returns also the translation
    *content*, what the above one doesn't.
  */
  @action
  public fetchPage = async (documentId: string) => {
    const page = await API.getPage(this.backendPassword, this.APISubdomain, documentId);
    this.documents.set(documentId, page);
    return page;
  };

  @action
  public fetchMove = async () => {
    try {
      const move = await API.getMove(this.backendPassword, this.APISubdomain);
      this.move = move;
      this.moveKeyInfo = move.generalInfos;
      this.hideFloorSelection = move.hideFloorSelection;

      // initialise localisation options
      this.stores.localesStore.initialiseLocales(move, i18n);

      if (this.stores.applicationStore.mode === ApplicationMode.Admin) {
        this.fetchUnassignedPeople();
      }
      return true;
    } catch (UnauthorizedException) {
      return false;
    }
  };

  private unassignedPeopleAlreadyFetched = false;
  private fetchUnassignedPeople = async () => {
    if (!this.unassignedPeopleAlreadyFetched) {
      this.unassignedPeopleAlreadyFetched = true;
      try {
        const people = await API.getUnassignedOwners(this.backendPassword, this.APISubdomain);
        this.createOwner(people);
      } catch (error) {
        this.unassignedPeopleAlreadyFetched = false;
      }
    }
  };

  @action
  public fetchContacts = async () => {
    const data = await API.getContacts(this.backendPassword, this.APISubdomain);
    this.contactInfo = data;
  };

  @action
  public fetchFAQ = async () => {
    const data = await API.getFAQ(this.backendPassword, this.APISubdomain);
    this.faq = data;
    this.faQuestions = data.map((el) => {
      return {
        question: el.question,
        answer: el.answer,
        isOpen: false,
      };
    });
  };

  @action
  public toggleFAQ = (faq: FAQuestion) => {
    faq.isOpen = !faq.isOpen;
  };

  @action
  public setEditState = (state: FloorPlanEditState) => {
    this.editState = state;
  };

  @action
  public setStickersNumberForMove = (stickersNumber: number) => {
    if (this.move) {
      this.move.stickersNumber = stickersNumber;
      const writableMove = moveToWritableMove(this.move);
      API.saveMove(this.backendPassword, this.APISubdomain, writableMove);
    }
  };

  @action
  public setCurrentFloor = (floor: Floor) => {
    this.currentFloor = floor;
  };

  @action
  public setCurrentFloorBasedOnUrlParam = (floorIdStr: string) => {
    const floor = this.floors.find((floor) => floor.id === floorIdStr);
    if (floor && floor !== this.currentFloor) {
      runInAction(() => {
        this.setCurrentFloor(floor);
      });
    }
  };

  // Add new space marker.
  @action
  public addSpace = (tag: SpaceTag) => {
    if (isNil(this.currentFloor)) {
      return;
    }
    if (this.currentFloor.spaces.includes(tag)) {
      return;
    }
    this.currentFloor.spaces.push(tag);
    this.saveNewSpaceTag(tag);
  };

  // Add new table marker.
  @action
  public addTable = (tag: TableTag) => {
    if (isNil(this.currentFloor)) {
      return;
    }
    const currentSpace = getSpaceByTable(this.currentFloor, tag);
    if (currentSpace) {
      if (currentSpace.tables.includes(tag)) {
        return;
      }
      currentSpace.tables.push(tag);
    }
    this.saveNewTableTag(tag);
  };

  // Edit space title or openSpace value of space marker.
  @action editSpace = async (tag: SpaceTag, title: string, openSpace: boolean) => {
    if (isNil(this.currentFloor)) {
      return;
    }
    const index = this.currentFloor.spaces.findIndex((space) => {
      return space === tag;
    });

    if (index > -1) {
      this.currentFloor.spaces[index].title = title;
      this.currentFloor.spaces[index].openSpace = openSpace;
    }

    await API.updateMarker(
      this.backendPassword,
      this.APISubdomain,
      SpaceTagToMarker(tag, this.currentFloor),
    );
    if (!isNil(this.currentFloor)) {
      this.refreshSingleLayout(this.currentFloor.id);
    }
  };

  // Edit space or letter value of table marker.
  // Changing space removes old table marker and creates a new one with same xy-position.
  @action editTable = async (tag: TableTag, spaceTitle: string, letter: string) => {
    if (isNil(this.currentFloor)) {
      return;
    }
    const currentSpace = getSpaceByTable(this.currentFloor, tag);

    if (currentSpace) {
      const currentTable = getTableByTag(currentSpace, tag);

      if (currentTable) {
        // To edit only letter:
        if (tag.space === spaceTitle) {
          currentTable.letter = letter;
        } else {
          // Remove tag from previous space & add new one.
          currentSpace.tables = currentSpace.tables.filter((table) => table !== currentTable);
          this.addTable({
            space: spaceTitle,
            letter,
            positionX: tag.positionX,
            positionY: tag.positionY,
          });
        }
      }
    }

    await API.updateMarker(
      this.backendPassword,
      this.APISubdomain,
      TableTagToMarker(tag, this.currentFloor),
    );
    if (!isNil(this.currentFloor)) {
      this.refreshSingleLayout(this.currentFloor.id);
    }
  };

  // Remove space from current floor. + Remove owners including this space from assignedOwners.
  @action
  removeSpace = async (tag: SpaceTag) => {
    if (isNil(this.currentFloor)) {
      return;
    }
    const currentSpace = getSpaceByTag(this.currentFloor, tag);

    if (currentSpace) {
      this.currentFloor.spaces = this.currentFloor.spaces.filter((space) => space !== currentSpace);
    }

    this.owners.forEach((owner) => {
      if (owner.space === currentSpace) {
        owner.floor = null;
        owner.space = null;
        owner.table = null;
      }
    });

    // TODO: The owners of this space are removed in the process.
    // Add alert dialog and return owners to unassigned list?
    const tagId = tag.id;
    if (tagId != null) {
      const response = await API.removeMarker(this.backendPassword, this.APISubdomain, tagId);
      console.dir(response);
    }
    if (!isNil(this.currentFloor)) {
      this.refreshSingleLayout(this.currentFloor.id);
    }
  };

  // TODO: Before calling, alert user in case table includes an owner.
  @action
  removeTable = async (tag: TableTag) => {
    if (isNil(this.currentFloor)) {
      return;
    }
    const currentSpace = getSpaceByTable(this.currentFloor, tag);
    if (!currentSpace) {
      return;
    }

    const currentTable = getTableByTag(currentSpace, tag);
    currentSpace.tables = currentSpace.tables.filter((table) => table !== currentTable);

    this.owners.forEach((owner) => {
      if (
        owner.floor === this.currentFloor &&
        owner.space === currentSpace &&
        owner.table === currentTable
      ) {
        owner.table = null;
      }
    });
    if (!isNil(tag.id)) {
      const response = await API.removeMarker(this.backendPassword, this.APISubdomain, tag.id);
      console.dir(response);
    }
    if (!isNil(this.currentFloor)) {
      this.refreshSingleLayout(this.currentFloor.id);
    }
  };

  // Defines currently selected space for connecting owners.
  @action
  public setSpaceSelection = (tag: SpaceTag | null) => {
    this.spaceSelection = tag;
  };

  // --------OWNER RELATED ACTIONS-----------

  // Add new unassigned owner.
  @action
  public createOwner = (arg: string | string[]) => {
    let ownersNames: string[];
    Array.isArray(arg) ? (ownersNames = arg) : (ownersNames = [arg]);

    const newOwners: Owner[] = ownersNames.map((ownerName) => ({
      key: Math.random().toString(36).substring(7),
      name: ownerName,
      stickersNumber: null,
      floor: null,
      space: null,
      table: null,
    }));
    this.owners = this.owners.concat(newOwners);
  };

  // Assign owner to some space.
  @action
  public assignOwner = async (owner: Owner, tag: SpaceTag) => {
    if (!this.currentFloor) {
      return;
    }
    const currentSpace = getSpaceByTag(this.currentFloor, tag);
    this.setSpaceSelection(currentSpace);

    owner.floor = this.currentFloor;
    owner.space = tag;
    owner.table = null;
    if (currentSpace && currentSpace.id) {
      const response = await API.saveNewOwner(
        this.backendPassword,
        this.APISubdomain,
        currentSpace.id,
        owner.name,
      );
      if (response.data.id) {
        owner.id = response.data.id;
        owner.key = response.data.id;
      }
    }
  };

  @action
  public assignOwnerWithName = (owner: Owner, spaceName: string) => {
    if (!this.currentFloor) {
      return;
    }
    const space = this.currentFloor.spaces.find((space) => space.title === spaceName);
    if (space) {
      this.assignOwner(owner, space);
    }
  };

  // Assign owner to some table. Owner already has a Space.
  @action
  public assignOwnerTable = async (owner: Owner, tableLetter: string) => {
    if (!owner || !owner.space) {
      return;
    }

    const currentTable = getTableByLetter(owner.space, tableLetter);
    owner.table = currentTable;

    const response = await API.updateMarkerOwner(this.backendPassword, this.APISubdomain, owner);
    if (response && response.data && response.data.id) {
      owner.id = response.data.id;
      owner.key = response.data.id;
    }
  };

  // Add new unassigned owner.
  @action
  public setStickersNumberForOwner = async (ownerKey: string, stickersNumber: number | null) => {
    const owner = this.owners.find((o) => o.key === ownerKey);
    if (!owner || (stickersNumber !== null && isNaN(stickersNumber!))) {
      return;
    }
    owner.stickersNumber = stickersNumber;
    await API.updateMarkerOwner(this.backendPassword, this.APISubdomain, owner);
  };

  // Return once assigned owner back to unassigned.
  @action
  public returnOwner = async (owner: Owner) => {
    await API.deleteMarkerOwner(this.backendPassword, this.APISubdomain, owner.id);

    owner.floor = null;
    owner.space = null;
    owner.table = null;

    // TODO: Nice to have: Returns the owner to top of the list.
  };

  // Remove an owner from unassignedOwners list. When deleting OR assigning.
  @action
  public deleteOwner = (owner: Owner) => {
    const index = this.owners.indexOf(owner);
    if (index >= 0) {
      this.owners.splice(index, 1);
    }
  };

  @action
  public saveNewLayout = async (
    floorName: string,
    file: File,
    labelColor: string | null,
    floorSize: FloorSize,
  ) => {
    const response = await API.postNewLayout(
      this.backendPassword,
      this.APISubdomain,
      floorName,
      file,
      labelColor,
      floorSize,
    );
    await this.refreshLayouts();
    return response;
  };

  @action
  public deleteLayout = async (id: string) => {
    const response = await API.deleteLayout(this.backendPassword, this.APISubdomain, id);
    await this.refreshLayouts();
    return response;
  };

  @action
  public refreshLayouts = async () => {
    const layouts = await API.getAllLayouts(this.backendPassword, this.APISubdomain);

    // Get data for all the layouts
    const allLayouts = await Promise.all(
      layouts.map((layout) => {
        return API.getLayoutData(this.backendPassword, this.APISubdomain, layout.id);
      }),
    );

    const parsedFloors: Floor[] = allLayouts.map(parseLayout);

    const refreshedListOfAssignedOwners: Owner[] = flatten(
      allLayouts.map((layout, i) => parseAssigns(layout, parsedFloors[i])),
    );

    runInAction(() => {
      this.floors = parsedFloors.sort((a, b) => parseInt(b.title) - parseInt(a.title));
      /*
        refreshedListOfAssignedOwners includes only owners that have been assigned
        to a space or a table at the moment (only spaces and tables are part of a
        *layout*. However, we might have some unassigned owners as well (because
        those come from a separate API endpoint, or because user has already added
        some more in the UI). Because of this we need to preserve those,
        even though we "replace" the assigned ones with the ones that came from
        backend
      */
      this.owners = this.unAssignedOwners.concat(refreshedListOfAssignedOwners);
      // Default: Choose the first floor in the array
      let nextFloor = this.floors && this.floors[0];
      const defaultFloor = this.floors.find((f) => f.isDefault);
      if (defaultFloor) nextFloor = defaultFloor;

      // But if there was already a current floor, try to
      // find a similar one from the new array
      const id = this.currentFloor && this.currentFloor.id;
      if (id) {
        const similarFloor = this.floors.find((f) => f.id === id);
        if (similarFloor) {
          nextFloor = similarFloor;
        }
      }

      if (nextFloor) {
        this.setCurrentFloor(nextFloor);
      }
    });
  };

  @action
  public refreshSingleLayout = async (id: string) => {
    const layoutData = await API.getLayoutData(this.backendPassword, this.APISubdomain, id);
    const parsedLayout = parseLayout(layoutData);
    const floorIndex = this.floors.findIndex((floor) => floor.id === id);
    const refreshedListOfAssignedOwners = parseAssigns(layoutData, this.floors[floorIndex]);
    runInAction(() => {
      // Set layout data in this.floors
      this.floors[floorIndex] = parsedLayout;
      // Set current floor
      this.currentFloor = this.floors[floorIndex];
      /*
          refreshedListOfAssignedOwners includes only owners that have been assigned
          to a space or a table at the moment (only spaces and tables are part of a
          *layout*. However, we might have some unassigned owners as well (because
          those come from a separate API endpoint, or because user has already added
          some more in the UI). Because of this we need to preserve those,
          even though we "replace" the assigned ones with the ones that came from
          backend
        */
      this.owners = this.unAssignedOwners.concat(refreshedListOfAssignedOwners);
    });
  };

  @action
  public saveNewSpaceTag = async (tag: SpaceTag) => {
    const floor = this.currentFloor;
    if (!isNil(floor)) {
      const marker: FloorMarker = SpaceTagToMarker(tag, floor);
      await API.postNewMarker(this.backendPassword, this.APISubdomain, marker);
      if (!isNil(this.currentFloor)) {
        this.refreshSingleLayout(this.currentFloor.id);
      }
    }
  };

  @action
  public saveNewTableTag = async (tag: TableTag) => {
    const floor = this.currentFloor;
    if (!isNil(floor)) {
      const marker: FloorMarker = TableTagToMarker(tag, floor);
      await API.postNewMarker(this.backendPassword, this.APISubdomain, marker);
      if (!isNil(this.currentFloor)) {
        this.refreshSingleLayout(this.currentFloor.id);
      }
    }
  };

  public getEffectiveScale() {
    return (this.currentFloor?.elementsScale || 0) * constants.ZOOM_LEVEL_INCREMENT + 1;
  }
}
