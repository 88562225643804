import React, { Component } from "react";
import { TFunction } from "i18next";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import colors from "../../../utils/colors";
import EmployeeUiLayout, {
  WithoutNormalContentAreaPadding,
} from "../../../components-mobile/EmployeeUiLayout";
import InfoCard from "../../../components-mobile/InformationCard";
import { InfoType, PageDocumentWithTranslations } from "../../../types/move";
import { FloorPlanStore } from "../../../stores/floorplan";
import { withTranslation, WithTranslation } from "react-i18next";
import { MoveStore } from "../../../stores/move";
import { onDesktop, OnlyOnDesktop } from "../../../utils/breakpoint";
import ContactButtons from "../../../components-mobile/ContactButtons";
import TextBlockLink from "../../../components-mobile/TextBlockLink";
import FloorImageThumbnail from "../../../components-mobile/FloorImageThumbnail";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Title, GreenTitle } from "../../../components/BrandText";
import { Timeline } from "../../../components/Timeline";
import { LocalesStore } from "../../../stores/LocalesStore";
import { UploadedDocument } from "../../../types/api";
import ButtonBlockLink from "../../../components-mobile/ButtonBlockLink";
import { ApplicationStore } from "../../../stores/application";

const Columns = styled.div`
  ${onDesktop} {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div`
  display: none;
  ${onDesktop} {
    display: block;
  }
`;

const LinkArea = styled(Link)`
  text-decoration: none;
`;

const titleChanges = `
  ${onDesktop} {
    color: ${colors.WHITE};
  }
  svg {
    vertical-align: text-bottom;
  }
`;
const Title2 = styled(Title)`
  ${titleChanges}
`;
const GreenTitle2 = styled(GreenTitle)`
  ${titleChanges}
`;

const WhiteBackground = styled.div`
  background-color: ${colors.WHITE};
  padding: 32px 8px;
`;

const InfoDocuments = styled.div`
  margin-top: 20px;
`;

type IProps = WithTranslation & {
  floorPlanStore: FloorPlanStore;
  moveStore?: MoveStore;
  localesStore: LocalesStore;
  applicationStore: ApplicationStore;
};

@inject("floorPlanStore")
@inject("moveStore")
@inject("localesStore")
@inject("applicationStore")
@observer
class InformationDashboard extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.renderDocuments = this.renderDocuments.bind(this);
  }

  async componentDidMount() {
    // This check is for preventing making the first API request too early when this component is used in admin UI
    if (this.props.floorPlanStore.backendPassword !== "") {
      this.props.floorPlanStore.fetchPages();
      this.props.floorPlanStore.fetchUploadedDocuments();
    }
  }

  downloadDocument = async (uploadedDocumentId: string, filename: string) => {
    const documentBlob = await this.props.floorPlanStore.downloadSingleDocument(uploadedDocumentId);
    const url = window.URL.createObjectURL(documentBlob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    if (typeof window.chrome !== "undefined") {
      link.setAttribute("view", filename);
    } else {
      link.setAttribute("download", filename);
    }
    link.click();
  };

  renderDocuments = (documents: PageDocumentWithTranslations[], language: string) =>
    documents
      .filter((document) => document.published === true)
      .map((document, i) => {
        const translation = document.translations.find((t) => t.locale === language);
        const { secondaryColor, darkerSecondaryColor } = this.props.applicationStore;
        return translation ? (
          <TextBlockLink
            customcolor={secondaryColor}
            customdarkercolor={darkerSecondaryColor}
            color={i % 2 === 0 ? "darkgreen" : "green"}
            key={i}
            to={"/move/info/doc/" + document.id}
            text={translation.title}
          />
        ) : null;
      });

  renderFaq = (documents: Map<any, any>, t: TFunction) => {
    const { secondaryColor, darkerSecondaryColor } = this.props.applicationStore;
    return (
      <TextBlockLink
        customcolor={secondaryColor}
        customdarkercolor={darkerSecondaryColor}
        color={documents.size % 2 === 0 ? "darkgreen" : "green"}
        to="/move/FAQ"
        text={t("contact.actions.faq")}
      />
    );
  };

  renderUploadedDocuments = (uploadedDocuments: UploadedDocument[]) =>
    uploadedDocuments.map((document, i) => {
      const { secondaryColor, darkerSecondaryColor } = this.props.applicationStore;
      return (
        <ButtonBlockLink
          customcolor={secondaryColor}
          customdarkercolor={darkerSecondaryColor}
          color={"blue"}
          key={i}
          text={document.originalFilename}
          onClick={() => this.downloadDocument(document.id, document.originalFilename)}
        />
      );
    });

  render() {
    if (!this.props.moveStore) {
      return null;
    }

    const { t } = this.props;
    const { documents, uploadedDocuments } = this.props.floorPlanStore;
    const { headingColor } = this.props.applicationStore;
    const locale = this.props.localesStore.locale;

    const arrow = <FiChevronRight />;

    return (
      <EmployeeUiLayout backgroundStyle="cover">
        <Columns>
          <LeftColumn>
            <Title2 style={{ color: headingColor }}>{t("info.title")}</Title2>
            <InfoCard
              infoType={InfoType.General}
              infoData={this.props.floorPlanStore.moveKeyInfo}
            />

            <GreenTitle2 style={{ color: headingColor }}>
              {t("info.documents_subtitle")}
            </GreenTitle2>
            <WithoutNormalContentAreaPadding>
              <InfoDocuments>
                {this.renderDocuments(Array.from(documents.values()), locale.backend)}
                {this.renderFaq(documents, t)}
              </InfoDocuments>
            </WithoutNormalContentAreaPadding>

            <OnlyOnDesktop>
              <LinkArea to="/move/contact">
                <Title2 style={{ color: headingColor }}>
                  {t("contact.title")} {arrow}
                </Title2>
              </LinkArea>
              <ContactButtons appearance="text" backTo="/move/info" backText={t("info.title")} />
            </OnlyOnDesktop>

            <GreenTitle2 style={{ color: headingColor }}>
              {t("info.uploaded_documents_subtitle")}
            </GreenTitle2>
            <WithoutNormalContentAreaPadding>
              <InfoDocuments>
                {this.renderUploadedDocuments(Array.from(uploadedDocuments.values()))}
              </InfoDocuments>
            </WithoutNormalContentAreaPadding>
          </LeftColumn>
          <RightColumn>
            <OnlyOnDesktop>
              <Title2 style={{ color: headingColor }}>{t("schedule.title")}</Title2>
              <WhiteBackground>{this.props.floorPlanStore.move && <Timeline />}</WhiteBackground>

              <LinkArea to="/move/floor">
                <Title2 style={{ color: headingColor }}>
                  {t("floor_plan.title")} {arrow}
                </Title2>
                <WhiteBackground>
                  <FloorImageThumbnail />
                </WhiteBackground>
              </LinkArea>
            </OnlyOnDesktop>
          </RightColumn>
        </Columns>
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(InformationDashboard);
