import React from "react";
import styled from "styled-components";
import { SpaceTag } from "../types/floorplan";
import colors from "../utils/colors";

interface IProps {
  tag: SpaceTag;
  onClick: (event: React.MouseEvent) => void;
  zoom: number;
  scale: number;
  onMobile?: boolean;
}

const TagContainer = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
`;

const Space = styled.div<{ zoom: number }>`
  background-color: ${(props) => props.theme.primaryColorLight || colors.GREEN};
  height: ${(props) => props.zoom * 36}px;
  width: ${(props) => props.zoom * 40}px;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  display: inline-block;
`;

const ClosedSpace = styled.div<{ zoom: number }>`
  background-color: ${(props) => props.theme.primaryColor || colors.BLUE};
  height: ${(props) => props.zoom * 36}px;
  width: ${(props) => props.zoom * 40}px;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  display: inline-block;
  padding: 3px;
`;

const Title = styled.p<{ zoom: number }>`
  font-size: ${(props) => props.zoom * 11}px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.4px;
  line-height: ${(props) => props.zoom * 36}px;
  color: ${colors.WHITE};
  &:hover {
    cursor: pointer;
  }
  padding-top: 2px;
`;

const Group = styled.div<{ zoom: number }>`
  display: inline-block;
  left: ${(props) => props.zoom * 28}px;
  top: ${(props) => props.zoom * 2}px;
  height: ${(props) => props.zoom * 16}px;
  width: ${(props) => props.zoom * 16}px;
  border-radius: 8px;
  background-color: ${colors.BLUE};
  position: fixed;
  z-index: 3;
`;

const GroupSizeNumber = styled.p<{ zoom: number }>`
  color: ${colors.WHITE};
  font-size: ${(props) => props.zoom * 10}px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: ${(props) => props.zoom * 16}px;
  text-align: center;
`;

const SpaceMarker: React.FunctionComponent<IProps> = (props: IProps) => {
  const { tag, onClick, zoom, onMobile, scale } = props;
  const effectiveZoom = zoom * scale;

  return (
    <TagContainer
      style={{
        top: onMobile ? `${tag.positionY * zoom}%` : `${tag.positionY}%`,
        left: onMobile ? `${tag.positionX * zoom}%` : `${tag.positionX}%`,
      }}
      onClick={(event) => onClick(event)}
    >
      {tag.openSpace ? (
        <>
          <Space zoom={effectiveZoom}>
            <Title zoom={effectiveZoom}>{tag.title}</Title>
          </Space>
          {tag.tables.length > 0 && (
            <Group zoom={effectiveZoom}>
              <GroupSizeNumber zoom={effectiveZoom}>{tag.tables.length}</GroupSizeNumber>
            </Group>
          )}
        </>
      ) : (
        <>
          <ClosedSpace zoom={effectiveZoom}>
            <Space zoom={effectiveZoom}>
              <Title zoom={effectiveZoom}>{tag.title}</Title>
            </Space>
          </ClosedSpace>
          {tag.tables.length > 0 && (
            <Group zoom={effectiveZoom}>
              <GroupSizeNumber zoom={effectiveZoom}>{tag.tables.length}</GroupSizeNumber>
            </Group>
          )}
        </>
      )}
    </TagContainer>
  );
};
export default SpaceMarker;
