import React, { Component } from "react";
import styled from "styled-components";
import constants from "../utils/constants";
import Header from "./Header";
import { onDesktop, OnlyOnDesktop, OnlyOnMobile } from "../utils/breakpoint";
import MobileFooter from "./MobileFooter";
import { get } from "lodash";
import colors from "../utils/colors";
import { Title } from "../components/BrandText";
import { inject, observer } from "mobx-react";
import { ApplicationStore, InjectedApplicationStore } from "../stores/application";

const Container = styled.div`
  padding-top: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
`;
interface BackgroundProps {
  customColor: string;
  backgroundUrl: string;
}
function colorWithOpacity(color: string): string {
  return color + "80";
}
function backgroundCss(customColor: string): string {
  return (
    "linear-gradient(" + colorWithOpacity(customColor) + ", " + colorWithOpacity(customColor) + `)`
  );
}
const FullPageBackground = styled.div<BackgroundProps>`
  display: none;
  ${onDesktop} {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -900;
    background: ${(props) =>
      `${
        props.customColor ? backgroundCss(props.customColor) : `url("${props.backgroundUrl}")`
      }, url("/bg-images/full.jpg")`};
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
  }
`;

const containerWidth = 960;
const padding = 16;
const ResponsiveWidthAndPadding = styled.div`
  padding: ${padding}px;
  ${onDesktop} {
    max-width: ${containerWidth}px;
    margin: auto;
  }
`;

const LightWeightBorder = styled.div`
  margin: 0 -${padding}px ${padding}px -${padding}px;
  border-bottom: ${constants.LIGHTWEIGHT_BORDER};
  ${onDesktop} {
    margin: 0;
    border-bottom: ${(p: IProps) =>
      p.backgroundStyle === "slim" ? 0 : constants.LIGHTWEIGHT_BORDER};
  }
`;

const ReturnButtonContainer = styled(ResponsiveWidthAndPadding)`
  // There are no padding because on mobile there is already a padding of 16px on the top coming from the ContentArea
  padding-top: 0;
  ${onDesktop} {
    padding-top: ${padding}px;
  }
`;

const SlimBackgroundImage = styled.div<BackgroundProps>`
  display: none;
  ${onDesktop} {
    display: block;
    height: 146px;
    ${(props) =>
      `background: url("${props.backgroundUrl}"), ${
        props.customColor ? backgroundCss(props.customColor) : backgroundCss("#65adc8")
      }, url("/bg-images/slim-black.png");`}
    background-position: 50% 0%;
    background-repeat: no-repeat;
  }
`;

const TitleContainer = styled(ResponsiveWidthAndPadding)`
  padding-top: 0;
  padding-bottom: 0;
`;

const Title2 = styled(Title)`
  color: ${colors.WHITE};
  margin: 0;
  line-height: 146px;
`;

const ContentArea = styled(ResponsiveWidthAndPadding)`
  position: absolute;
  top: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
  bottom: ${constants.EMPLOYEE_UI_FOOTER_HEIGHT};
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;

  ${onDesktop} {
    position: static;
    padding-top: ${padding}px;
    padding-bottom: 64px;

    & > h1:first-child {
      margin-top: 16px;
    }
  }
`;

export const WithoutNormalContentAreaPadding = styled.div`
  margin-left: -${padding}px;
  margin-right: -${padding}px;
  ${onDesktop} {
    margin-left: 0;
    margin-right: 0;
  }
`;
type IState = {};
type IProps = {
  backgroundStyle?: "cover" | "slim";
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  title?: string;
  applicationStore?: ApplicationStore;
};

@inject("applicationStore")
@observer
class EmployeeUiLayout extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  get injected() {
    return this.props as InjectedApplicationStore;
  }
  render() {
    const { backgroundStyle, onClick, title } = this.props;

    const children = React.Children.toArray(this.props.children);
    const firstChildIsReturnButton = get(children, "[0].props.isReturnButton") === true;
    const returnButton = firstChildIsReturnButton ? children[0] : null;
    const remainingChildren = children.slice(firstChildIsReturnButton ? 1 : 0);
    const { backgroundColor, headingColor, backgroundUrl } = this.injected.applicationStore;

    let BackgroundContainer = null;
    if (backgroundStyle === "slim") {
      BackgroundContainer = SlimBackgroundImage;
    }
    return (
      <>
        <Header lightWeightBorder={backgroundStyle !== "cover"} />
        <Container onClick={onClick}>
          {backgroundStyle === "cover" && (
            <FullPageBackground customColor={backgroundColor} backgroundUrl={backgroundUrl} />
          )}

          <OnlyOnDesktop>
            {returnButton && (
              <LightWeightBorder {...this.props}>
                <ReturnButtonContainer>{returnButton}</ReturnButtonContainer>
              </LightWeightBorder>
            )}

            {BackgroundContainer && (
              <BackgroundContainer customColor={backgroundColor} backgroundUrl={backgroundUrl}>
                {title && (
                  <TitleContainer>
                    <Title2 style={{ color: headingColor }}>{title}</Title2>
                  </TitleContainer>
                )}
              </BackgroundContainer>
            )}
          </OnlyOnDesktop>

          <ContentArea>
            {returnButton && (
              <OnlyOnMobile>
                <LightWeightBorder>
                  <ReturnButtonContainer>{returnButton}</ReturnButtonContainer>
                </LightWeightBorder>
              </OnlyOnMobile>
            )}

            {remainingChildren}
          </ContentArea>
        </Container>
        <MobileFooter />
      </>
    );
  }
}
export default EmployeeUiLayout;
