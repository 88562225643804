import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import constants from "../utils/constants";
import { MobXProviderContext, observer } from "mobx-react";
import MainNavigationElements from "./MainNavigationElements";
import { onDesktop } from "../utils/breakpoint";
import { ApplicationStore } from "../stores/application";

interface IProps {
  customColor: string;
}

const Container = styled.div<IProps>`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: ${constants.EMPLOYEE_UI_FOOTER_HEIGHT};
  flex-direction: row;
  background-color: ${(p: IProps) => (p.customColor ? p.customColor : colors.BLUE)}
  margin: 0;
  z-index: 10;

  &,
  a,
  a:hover,
  a:active {
    color: ${colors.WHITE};
  }

  ${onDesktop} {
    display: none;
  }
`;

const MobileFooter: React.FunctionComponent = observer(() => {
  const { applicationStore } = React.useContext<{
    applicationStore: ApplicationStore;
  }>(MobXProviderContext);
  const { headingColor } = applicationStore;
  return (
    <Container customColor={headingColor}>
      <MainNavigationElements />
    </Container>
  );
});
export default MobileFooter;
