import React from "react";
import styled from "styled-components";
import { TableTag } from "../types/floorplan";
import colors from "../utils/colors";

interface IProps {
  tag: TableTag;
  onClick: (event: React.MouseEvent) => void;
  zoom: number;
  scale: number;
  onMobile?: boolean;
}

const TagContainer = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  &:hover {
    cursor: pointer;
  }
`;

const Table = styled.div<{ zoom: number }>`
  background-color: ${colors.TURQUOISE};
  height: ${(props) => props.zoom * 36}px;
  width: ${(props) => props.zoom * 36}px;
  border-radius: 18px;
  display: inline-block;
`;

const Title = styled.p<{ zoom: number }>`
  font-size: ${(props) => props.zoom * 9}px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.4px;
  color: ${colors.WHITE};
  &:hover {
    cursor: pointer;
  }
  padding-top: 2px;
`;

const TableMarker: React.FunctionComponent<IProps> = (props: IProps) => {
  const { tag, onClick, zoom, onMobile, scale } = props;
  const effectiveScale = zoom * scale;

  return (
    <TagContainer
      style={{
        top: onMobile ? `${tag.positionY * zoom}%` : `${tag.positionY}%`,
        left: onMobile ? `${tag.positionX * zoom}%` : `${tag.positionX}%`,
      }}
      onClick={(event) => onClick(event)}
    >
      <Table zoom={effectiveScale}>
        <Title zoom={effectiveScale}>{tag.space + tag.letter}</Title>
      </Table>
    </TagContainer>
  );
};
export default TableMarker;
