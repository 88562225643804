export enum InfoType {
  General = "general",
  Contact = "contact",
}

export type FAQuestion = {
  question: string;
  answer: string;
  isOpen: boolean;
};

export type PageDocumentWithTranslations = {
  id: string;
  translations: DocumentTranslation[];
  published: boolean;
};

export type DocumentTranslation = {
  locale: string;
  title: string;
  content: string;
};

export type PageDocument = DocumentTranslation & {
  id: string;
};
