import styled from "styled-components";
import colors from "../utils/colors";

export const Title = styled.h1`
  color: ${colors.BLUE};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 20px;
  margin: 32px 0 16px 0;
  text-transform: uppercase;
`;

export const GreenTitle = styled(Title)`
  color: ${colors.GREEN_DARK};
`;

export const SubtitleWithoutMargins = styled.div`
  color: ${colors.SUBTITLE_DEFAULT};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.55px;
  line-height: 18px;
`;

export const Subtitle = styled(SubtitleWithoutMargins)`
  margin-top: 40px;
  :first-child {
    margin-top: 0;
  }
`;

export const SubtitleSmallWithoutMargins = styled(SubtitleWithoutMargins)`
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 18px;
`;

export const SubtitleSmall = styled(SubtitleSmallWithoutMargins)`
  margin-top: 16px;
  :first-child {
    margin-top: 0;
  }
`;

export const Description = styled.p`
  color: ${colors.TEXT_DEFAULT};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 18px;
  margin-top: 16px;
`;

export const TextArea = styled.textarea`
  width: calc(100% - 14px);
  height: 140px;
  margin: 0;
  padding: 6px;
  resize: none;
  border: 0px;
  color: ${colors.DARK_GRAY};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 16px;
  &:focus {
    outline: none;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.WHITE};
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.TP_TURQUOISE};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.TURQUOISE};
  }
`;
