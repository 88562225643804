import StoresComponent from "./StoresComponent";
import SearchApi from "js-worker-search";
import { observable, action, autorun, flow, reaction } from "mobx";
import { defer } from "lodash";
import { Owner } from "../types/floorplan";
import { CancellablePromise } from "mobx/lib/api/flow";
import constants from "../utils/constants";

export class PeopleStore {
  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
    defer(this.initializeSideEffects);
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  private searchApi = new SearchApi();

  @observable
  public searchResults: Owner[] = [];

  @observable
  private searchTerms = "";

  private currentSearch: CancellablePromise<unknown> | null = null;

  // --------------------------------------------------------------------------

  private initializeSideEffects = () => {
    autorun(() => {
      this.indexPeople(this.stores.floorPlanStore.owners);
    });
    reaction(
      () => [this.stores.floorPlanStore.owners, this.searchTerms],
      () => {
        this._cancelPreviousSearchAndInitiateNewOne();
      },
    );
  };

  // --------------------------------------------------------------------------

  @action
  private indexPeople(people: Owner[]) {
    people.forEach((person) => {
      if (!person.id) {
        return;
      }
      this.searchApi.indexDocument(person.id, person.name);
    });
  }

  @action
  public async search(str: string) {
    this.searchTerms = str;
    /*
      The reaction above will handle the initiation of an actual search
      this._cancelPreviousSearchAndInitiateNewOne is NOT called directly here,
      because doing a meaningful search we need BOTH search terms as well as
      people data. It might be that at this point we have the search terms,
      but not yet the people data. Using the reaction above eases the pain
      of handling different timing scenarios here.
    */
  }

  @action
  public async _cancelPreviousSearchAndInitiateNewOne() {
    if (this.currentSearch) {
      this.currentSearch.cancel();
    }

    this.currentSearch = this._initiateNewSearch();
    try {
      await this.currentSearch;
    } catch (error) {
      if (error && error.message !== "FLOW_CANCELLED") {
        console.error(error);
      }
    }
  }

  @action
  private _initiateNewSearch = flow(function* (this: PeopleStore) {
    try {
      const matchedIds: string[] = yield this.searchApi.search(this.searchTerms);

      // By mapping the results, we maintain the correct order of search results
      this.searchResults = matchedIds
        .map((id) => this.stores.floorPlanStore.owners.find((person) => person.id === id))
        .filter((person) => person !== undefined)
        .slice(0, constants.MAX_NUMBER_OF_SEARCH_RESULTS) as Owner[];
    } catch (error) {
      this.searchResults = [];
      console.error(error);
    }
    this.currentSearch = null;
  });
}
