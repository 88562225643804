import { MobXProviderContext } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import EmployeeUiLayout from "../../components-mobile/EmployeeUiLayout";
import { Title } from "../../components/BrandText";
import { Timeline } from "../../components/Timeline";
import { ApplicationStore } from "../../stores/application";

const ScheduleView: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { applicationStore } = React.useContext<{
    applicationStore: ApplicationStore;
  }>(MobXProviderContext);
  const { headingColor } = applicationStore;
  return (
    <EmployeeUiLayout>
      <Title style={{ color: headingColor }}>{t("schedule.title")}</Title>
      <Timeline />
    </EmployeeUiLayout>
  );
};

export default ScheduleView;
