import React from "react";
import styled from "styled-components";
import InfoCard from "./InformationCard";
import { ContactInfo } from "../types/api";
import { InfoType } from "../types/move";
import { onDesktop } from "../utils/breakpoint";

const Section = styled.section`
  width: 100%;
  min-height: 200px;
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  & > * {
    margin: 0 8px;
  }
  & > *:first-child {
    margin-left: 16px;
  }
  & > *:last-child {
    margin-right: 16px;
  }
  ${onDesktop} {
    & > *:first-child {
      margin-left: 0;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
`;

interface IProps {
  contactInfo: ContactInfo[];
}

const Slider: React.FunctionComponent<IProps> = (props: IProps) => {
  const { contactInfo } = props;

  const renderContactCards = () => {
    const cards = [];
    for (let i = 0; i < contactInfo.length; i++) {
      cards.push(<InfoCard key={i} infoType={InfoType.Contact} infoData={contactInfo[i]} />);
    }
    return cards;
  };

  return (
    <Section>
      {renderContactCards()}
      <div style={{ color: "transparent" }}>.</div>
    </Section>
  );
};

export default Slider;
