import React, { Component } from "react";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import { observer, inject } from "mobx-react";
import colors from "../../../utils/colors";
import EmployeeUiLayout from "../../../components-mobile/EmployeeUiLayout";
import ReturnButton from "../../../components-mobile/ReturnButton";
import { BrandButtonPrimary, BrandButtonNegative } from "../../../components/BrandButton";
import { FloorPlanStore } from "../../../stores/floorplan";
import { FeedbackType } from "../../../types/api";
import { RouteComponentProps } from "react-router";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { Title } from "../../../components/BrandText";
import { ApplicationStore } from "../../../stores/application";

export const Description = styled.p`
  color: ${colors.DARK_GRAY}
  font-size: 12px;
  letter-spacing: 0.42px;
  line-height: 14px;
  margin-top: 26px;
`;

export const FormField = styled.div`
  margin-top: 26px;
  width: 100%;
`;

export const FormLabel = styled.p`
  color: ${colors.GRAY}
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 12px;
  text-transform: uppercase;
`;

export const FormTextField = styled.input`
  width: -webkit-fill-available;
  margin-top: 8px;
  padding: 12px 16px;
  border: 1px solid ${colors.TP_TURQUOISE_DARK};
  &:focus {
    outline: 3px solid ${colors.TP_TURQUOISE_DARK};
  }

  color: ${colors.DARK_GRAY};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 16px;
`;

export const QuestionField = styled.textarea`
  width: -webkit-fill-available;
  margin-top: 8px;
  border: 1px solid ${colors.TP_TURQUOISE_DARK};
  &:focus {
    outline: 3px solid ${colors.TP_TURQUOISE_DARK};
  }
  resize: none;
  padding: 12px 16px;
  color: ${colors.DARK_GRAY};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 16px;
`;

export const BtnContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 26px;
`;

type IProps = RouteComponentProps<any> &
  WithTranslation & {
    floorPlanStore: FloorPlanStore;
    applicationStore: ApplicationStore;
  };

interface IState {
  name: string;
  email: string;
  number: string;
  question: string;
  snackbarMessage: string;
}

@inject("floorPlanStore")
@inject("applicationStore")
@observer
class QuestionForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: "",
      email: "",
      number: "",
      question: "",
      snackbarMessage: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = () => {
    this.props.floorPlanStore.sendFeedback({
      name: this.state.name,
      email: this.state.email,
      phone: this.state.number,
      feedback_type: FeedbackType.Question,
      question: this.state.question,
    }).then(result => {
      this.setState({
        name: "",
        email: "",
        number: "",
        question: "",
      });
      this.showMessage(this.props.t("feedback_form.sent"));
    }, error => {
      this.showMessage(this.props.t("feedback_form.didntsend"));
    });
  };

  showMessage(message: string) {
    this.setState({
      snackbarMessage: message,
    });
  };

  render() {
    const { t } = this.props;
    const { headingColor } = this.props.applicationStore;
    return (
      <EmployeeUiLayout>
        <Snackbar
          message={this.state.snackbarMessage}
          open={this.state.snackbarMessage !== ""}
          autoHideDuration={3000}
          onClose={() => {
            this.showMessage("");
          }}
        ></Snackbar>
        <ReturnButton
          customColor={headingColor}
          history={this.props.history}
          to="/move/contact"
          title={t("contact.return_button_link")}
        />
        <Title style={{ color: headingColor }}>{t("question_form.title")}</Title>
        <Description>
          <Trans i18nKey="question_form.description" />
        </Description>
        <FormField>
          <FormLabel>{t("form.name.label")}</FormLabel>
          <FormTextField
            placeholder={t("form.name.placeholder")}
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t("form.email.label")}</FormLabel>
          <FormTextField
            placeholder={t("form.email.placeholder")}
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t("form.phone.label")}</FormLabel>
          <FormTextField
            placeholder={t("form.phone.placeholder")}
            value={this.state.number}
            onChange={(e) => this.setState({ number: e.target.value })}
          />
        </FormField>
        <FormField>
          <FormLabel>{t("question_form.question.label")} *</FormLabel>
          <QuestionField
            rows={5}
            placeholder={t("question_form.question.placeholder")}
            value={this.state.question}
            onChange={(e) => this.setState({ question: e.target.value })}
          />
        </FormField>

        <BtnContainer>
          <BrandButtonNegative
            onClick={() => {
              this.setState({
                name: "",
                email: "",
                number: "",
                question: "",
              });
              this.props.history.push("/move/contact");
            }}
          >
            {t("buttons.cancel")}
          </BrandButtonNegative>
          <BrandButtonPrimary onClick={() => this.onSubmit()} disabled={this.state.question === ""}>
            {t("buttons.send")}
          </BrandButtonPrimary>
        </BtnContainer>
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(QuestionForm);
