import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import constants from "../utils/constants";
import { FloorPlanStore } from "../stores/floorplan";
import { MobXProviderContext, observer } from "mobx-react";
import { MoveStore } from "../stores/move";
import MainNavigationElements from "./MainNavigationElements";
import { onDesktop } from "../utils/breakpoint";
import { ApplicationStore } from "../stores/application";
import { LocalesStore } from "../stores/LocalesStore";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

type IProps = {
  lightWeightBorder?: boolean;
};

const NavHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};

  padding: 0 32px;
  display: flex;
  align-items: center;

  background-color: ${colors.WHITE};
  border-bottom: ${(p: IProps) => (p.lightWeightBorder ? constants.LIGHTWEIGHT_BORDER : 0)};
  z-index: 100;

  &,
  a,
  a:hover,
  a:active {
    color: ${colors.BLUE};
  }
`;

const Left = styled.div`
  flex: 1 1 150px;
`;
const Center = styled.div`
  display: none;
  ${onDesktop} {
    display: block;
  }
  height: ${constants.EMPLOYEE_UI_HEADER_HEIGHT};
  flex: 1 1 600px;
`;
const Right = styled.div`
  flex: 1 1 150px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ClientName = styled.p`
  color: ${colors.DARK_GRAY};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.16px;
  line-height: 14px;
`;

const LanguageIndicator = styled.div`
  background-color: ${colors.TURQUOISE};
  color: ${colors.WHITE};
  font-weight: bold;
  left: 4px;
  letter-spacing: 0.56px;
  padding: 3px 16px;
  position: absolute;
  margin: 0;
  max-width: 100px;
  width: 15%;
  text-align: center;
  text-transform: uppercase;
  top: 4px;
  z-index: 900;
`;

const LanguageSelectionContainer = styled.div`
  position: relative;
  padding-left: 1rem;
`;

const LanguageSelectorIcon = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const LanguageSelection = styled.ul`
  position: absolute;
  top: 1.4rem;
  right: 0;
  background-color: #ffffff;
  padding: 0.25rem;
  margin: 0.25rem;
  width: 8rem;
  text-align: center;
  box-shadow: 2px 1px 4px -1px #000000;
`;

const LocaleItem = styled.li<{ selected: boolean }>`
  list-style: none;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${colors.GRAY};
  color: ${(p) => (p.selected ? colors.BLUE : colors.BLACK)}
  &:hover {
    cursor: ${(p) => (p.selected ? "default" : "pointer")}
  }
`;

const languageTesting = () => process.env.REACT_APP_LANGUAGE_TESTING === "false";

const changeLanguage = (moveStore: MoveStore) => {
  if (languageTesting()) {
    // moveStore.setNextLocale();
  }
};

interface ILogoProps {
  logoUrl: string;
}

const useStyles = makeStyles(() => ({
  moveLogo: {
    display: "inine-block",
    height: "40px",
  },
}));

const Logo: React.FunctionComponent<ILogoProps> = (props: ILogoProps) => {
  const { moveLogo } = useStyles();

  return (
    <a href="/login">
      <img className={moveLogo} src={props.logoUrl} alt="logo" />
    </a>
  );
};

const Header: React.FunctionComponent<IProps> = observer((props: IProps) => {
  const { applicationStore, floorPlanStore, moveStore, localesStore } = React.useContext<{
    applicationStore: ApplicationStore;
    floorPlanStore: FloorPlanStore;
    moveStore: MoveStore;
    localesStore: LocalesStore;
  }>(MobXProviderContext);
  const { i18n } = useTranslation();

  const { isAuthorized } = applicationStore;

  const [isLocaleSelectorClicked, setIsLocaleSelectorClicked] = useState(false);

  const closeLanguageMenu = useCallback(() => {
    setIsLocaleSelectorClicked(false);
  }, []);

  useEffect(() => {
    /* if for some reason structure of the dom changes, this code will not work, so make sure to check the dom structure */
    const mainContentElement = document.querySelector("#root > div:nth-child(2)");
    if (mainContentElement) {
      mainContentElement.addEventListener("click", closeLanguageMenu);
    }

    return function cleanup() {
      mainContentElement && mainContentElement.removeEventListener("click", closeLanguageMenu);
    };
  }, [closeLanguageMenu]);

  const { moveLogoUrl } = floorPlanStore;

  return (
    <>
      {languageTesting() && !!localesStore.selectedLocale && (
        <LanguageIndicator onClick={() => changeLanguage(moveStore)}>
          {localesStore.selectedLocale.ui}
        </LanguageIndicator>
      )}
      <NavHeader {...props}>
        <Left>
          <Logo logoUrl={moveLogoUrl} />
        </Left>
        <Center>{isAuthorized && <MainNavigationElements />}</Center>
        <Right>
          {floorPlanStore.move && floorPlanStore.move.company.name && (
            <ClientName>{floorPlanStore.move.company.name}</ClientName>
          )}
          {localesStore.availableLocales.length > 1 && (
            <LanguageSelectionContainer>
              <LanguageSelectorIcon
                onClick={() => setIsLocaleSelectorClicked((prevValue) => !prevValue)}
              >
                <MdLanguage size={22} />
              </LanguageSelectorIcon>
              {isLocaleSelectorClicked && (
                <LanguageSelection>
                  {localesStore.availableLocales.map((locale) => {
                    const isSelected =
                      !!localesStore.selectedLocale &&
                      localesStore.selectedLocale.i18next === locale.i18next;
                    return (
                      <LocaleItem
                        key={locale.i18next}
                        selected={isSelected}
                        onClick={() => {
                          if (
                            !!localesStore.selectedLocale &&
                            localesStore.selectedLocale.i18next !== locale.i18next
                          ) {
                            i18n.changeLanguage(locale.i18next);
                            localesStore.setLocale(locale);
                          }
                          setIsLocaleSelectorClicked(false);
                        }}
                      >
                        {locale.ui}
                      </LocaleItem>
                    );
                  })}
                </LanguageSelection>
              )}
            </LanguageSelectionContainer>
          )}
        </Right>
      </NavHeader>
    </>
  );
});
export default Header;
