import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEnGB from "./locales/translations.en_GB.json";
import translationsNbNO from "./locales/translations.nb_NO.json";
import translationsDeDE from "./locales/translations.de_DE.json";
import translationsDaDK from "./locales/translations.da_DK.json";
import translationsSvSE from "./locales/translations.sv_SE.json";
import translationsNnNo from "./locales/translations.nn_NO.json";
import { chain } from "lodash";
import { isDayjs } from "dayjs";

// Remember to add new locales to this list as well!
import enLocale, { Locale as DayjsLocale } from "dayjs/locale/en";
import noLocale from "dayjs/locale/nb";
import deLocale from "dayjs/locale/de";
import daLocale from "dayjs/locale/da";
import seLocale from "dayjs/locale/sv";
import nnLocale from "dayjs/locale/nn";
import constants from "./utils/constants";

export type Locale = {
  iso: string;
  i18next: string;
  backend: string;
  dayjs: string;
  translation: Record<string, unknown>;
  ui: string;
  dayjsLocale: DayjsLocale;
};

export const i18nextLngKey = "i18nextLng";

export const locales: Locale[] = [
  {
    iso: "en_GB",
    i18next: "en_GB",
    backend: "en",
    dayjs: "en",
    translation: translationsEnGB,
    ui: "English",
    dayjsLocale: enLocale,
  },
  {
    iso: "nb_NO",
    i18next: "nb_NO",
    backend: "no",
    dayjs: "nb",
    translation: translationsNbNO,
    ui: "Norsk - bokmål",
    dayjsLocale: noLocale,
  },
  {
    iso: "nn_NO",
    i18next: "nn_NO",
    backend: "nn",
    dayjs: "nn",
    translation: translationsNnNo,
    ui: "Norsk - nynorsk",
    dayjsLocale: nnLocale,
  },
  {
    iso: "de_DE",
    i18next: "de_DE",
    backend: "de",
    dayjs: "de",
    translation: translationsDeDE,
    ui: "Deutsch",
    dayjsLocale: deLocale,
  },
  {
    iso: "da_DK",
    i18next: "da_DK",
    backend: "da",
    dayjs: "da",
    translation: translationsDaDK,
    ui: "Dansk",
    dayjsLocale: daLocale,
  },
  {
    iso: "sv_SE",
    i18next: "sv_SE",
    backend: "se",
    dayjs: "sv",
    translation: translationsSvSE,
    ui: "Svenska",
    dayjsLocale: seLocale,
  },
  {
    iso: "debug",
    i18next: "cimode",
    backend: "debug",
    dayjs: "en",
    translation: translationsEnGB,
    ui: "DEBUG",
    dayjsLocale: enLocale,
  },
];

export const localeByIso = (iso: string) => {
  return locales.find((l) => l.iso === iso);
};

const localeByI18Next = (i18next = "en_GB") => {
  return locales.find((l) => l.i18next === i18next) || locales[0];
};

export function localeByBackend(backendLocale: string) {
  return locales.find((locale) => locale.backend === backendLocale);
}

const resources = chain(locales)
  .keyBy("iso")
  .omit("cimode")
  .mapValues((locale) => ({ translation: locale.translation }))
  .value();

const currentLocale = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: currentLocale ? currentLocale : undefined,
    fallbackLng: (localeByIso(constants.FALLBACK_LANGUAGE_ISO) as Locale).i18next,
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, i18nextLanguage) {
        if (isDayjs(value)) {
          return value.locale(localeByI18Next(i18nextLanguage).dayjs).format(format);
        }
        return value;
      },
    },
  });

export default i18n;
