import React from "react";
import styled from "styled-components";
import { FloorPlanStore } from "../stores/floorplan";
import { MobXProviderContext, observer } from "mobx-react";

const Image = styled.img`
  width: 100%;
`;

const FloorImageThumbnail: React.FunctionComponent = observer(() => {
  const { floorPlanStore } = React.useContext<{
    floorPlanStore: FloorPlanStore;
  }>(MobXProviderContext);
  if (!floorPlanStore.currentFloor) {
    return null;
  }
  return (
    <>
      <Image src={floorPlanStore.currentFloor.image.url} alt="Preview of the first floor layout" />
    </>
  );
});

export default FloorImageThumbnail;
