import { ShowTimeline, TimelineSection } from "@fmg-packages/common-components";
import { MobXProviderContext, observer } from "mobx-react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationStore } from "../stores/application";
import { LocalesStore } from "../stores/LocalesStore";
import { MoveStore } from "../stores/move";
import API from "../utils/api";

const Timeline: React.FunctionComponent = observer(() => {
  const { applicationStore, localesStore } = React.useContext<{
    applicationStore: ApplicationStore;
    moveStore: MoveStore;
    localesStore: LocalesStore;
  }>(MobXProviderContext);

  const [timelineSections, setTimelineSections] = useState<TimelineSection[]>([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    async function fetchTimelineSections() {
      try {
        const timelineSections = await API.fetchTimelineSections(
          applicationStore.password,
          applicationStore.subdomain,
        );

        const formattedTimelineSections = timelineSections.map((timelineSection) => ({
          ...timelineSection,
          date: timelineSection.date.locale(localesStore.locale.dayjsLocale),
          events: timelineSection.events.map((timelineSectionEvent) => ({
            ...timelineSectionEvent,
            startDate: timelineSectionEvent.startDate.locale(localesStore.locale.dayjsLocale),
            endDate: timelineSectionEvent.endDate
              ? timelineSectionEvent.endDate.locale(localesStore.locale.dayjsLocale)
              : null,
          })),
        }));
        setTimelineSections(formattedTimelineSections);
      } catch (error) {
        console.log(error);
        setError("Failed to fetch timeline data");
      }
    }

    fetchTimelineSections();
  }, [applicationStore.password, applicationStore.subdomain, localesStore.locale.dayjsLocale]);

  return (
    <>
      {error === "" ? (
        timelineSections.length > 0 ? (
          <ShowTimeline timelineSections={timelineSections} oppositeContentSpacing={0.7} />
        ) : (
          <p>{t("timeline.noTimeline")}</p>
        )
      ) : (
        <p>{t("timeline.fetchErrorMessage")}</p>
      )}
    </>
  );
});

export { Timeline };
