export default {
  BLUE: "#007EAB",
  BLUE_LIGHT: "#0097CD",
  DARK_GRAY: "#3C3C3C",
  GREEN: "#00DE9B",
  GREEN_DARK: "#00AF7A",
  GREEN_DARKEST: "#009769",
  GRAY: "#A3A3A3",
  RED: "#A65D5D",
  TURQUOISE_LIGHT: "#A7E8FF",
  TURQUOISE: "#57C3EA",
  WHITE: "#FFFFFF",
  WHITE_COLD: "#F6FDFF",
  WHITE_WARM: "#F3F3F3",
  BLACK: "#040000",

  // TRANSPARENT COLORS
  TP_BLACK: "rgba(0,0,0,0.05)",
  TP_BLUE: "rgba(167,232,255,0.1)",
  TP_GRAY: "rgba(151,151,151,0.3)",
  TP_GREEN: "rgba(0,175,122,0.9)",
  TP_TURQUOISE: "rgba(167,232,255,0.4)",
  TP_TURQUOISE_DARK: "rgba(87, 195, 234, 0.2)",
  TP_WHITE: "rgba(255,255,255,0.8)",

  TITLE_DEFAULT: "#007EAB",
  SUBTITLE_DEFAULT: "#A3A3A3",
  TEXT_DEFAULT: "#3C3C3C",

  // EXTRA COLORS
  BG_BLUE_LIGHT: "#EDFAFF",
  BG_GRAY_LIGHT: "#E0E0E0",

  RED_DARK: "#854949",
  RED_LIGHT: "#B06363",

  // COLORS IN BRANDBUTTONS
  GREEN_LIGHTEST: "#DFFDF4",
  BLUE_LIGHTEST: "#E2F3F9",
  RED_LIGHTEST: "#FFF7F7",

  TRANSPARENT: "transparent",
};
