import React from "react";
import { Provider, MobXProviderContext, observer } from "mobx-react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import Login from "./containers/Login";
import MoveNav from "./containers/Navigation/MoveNavigation";
import StoresComponent from "./stores/StoresComponent";
import { MoveStore } from "./stores/move";
import { ApplicationStore } from "./stores/application";

import { ThemeProvider } from "@material-ui/core/styles";
import relokatorMuiTheme from "./utils/muiTheme";
import { PuffLoader } from "react-spinners";
import colors from "./utils/colors";

type IProps = RouteComponentProps & {
  moveStore?: MoveStore;
};

const AppWithState: React.FunctionComponent<IProps> = (props: IProps) => {
  const { applicationStore } = React.useContext<{ applicationStore: ApplicationStore }>(
    MobXProviderContext,
  );
  const logout = () => {
    applicationStore.clearPassword();

    return <Redirect to="/login" />;
  };

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" render={logout} />

      <Route exact path="/">
        <Redirect to="/login" />
      </Route>

      <Route path="/move" component={MoveNav} />
    </Switch>
  );
};

class Document extends React.Component {
  componentDidMount() {
    document.title = "FMG";
  }

  render() {
    return <></>;
  }
}

const AppWithStateAndRouter = withRouter(AppWithState);
const css = `
position: fixed;
z-index: 999;
overflow: show;
margin: auto;
top: 0;
left: 0;
bottom: 0;
right: 0;
width: 50px;
height: 50px;
`;

@observer
class EmployeeUIApp extends StoresComponent {
  render() {
    return (
      <Provider {...this.stores}>
        <Document />
        <Router>
          {this.applicationStore.isLoading && (
            <PuffLoader css={css} color={colors.BLUE}></PuffLoader>
          )}
          {!this.applicationStore.isLoading && (
            <ThemeProvider theme={relokatorMuiTheme}>
              <AppWithStateAndRouter />
            </ThemeProvider>
          )}
        </Router>
      </Provider>
    );
  }
}

export default EmployeeUIApp;
