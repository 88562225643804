import dayjs from "dayjs";
import { i18n } from "i18next";
import { action, computed, observable } from "mobx";
import { i18nextLngKey, Locale, localeByBackend, localeByIso } from "../i18n";
import { Move } from "../types/floorplan";
import StoresComponent from "./StoresComponent";

class LocalesStore {
  @observable
  availableLocales: Locale[] = [];

  @observable
  defaultLocale: Locale | undefined;

  @observable
  selectedLocale: Locale | undefined;

  @observable
  fallbackLocale: Locale;

  constructor(private stores: StoresComponent, fallbackLocale: Locale) {
    this.fallbackLocale = fallbackLocale;
  }

  @action
  setAvailableLocales(availableLocales: Locale[]) {
    this.availableLocales = availableLocales;

    const existingLanguageSelection = localStorage.getItem(i18nextLngKey);
    if (existingLanguageSelection) {
      const foundLocale = availableLocales.find((availableLocale) =>
        localeByIso(availableLocale.iso),
      );

      if (!foundLocale) {
        // the locale that is currently selected is not supported, remove it from local storage
        localStorage.removeItem(i18nextLngKey);
      }
    }
  }

  @action
  setLocale(locale: Locale) {
    this.selectedLocale = locale;
    dayjs.locale(locale.dayjsLocale);
  }

  @action
  setDefaultLocale(defaultLocale: Locale) {
    this.defaultLocale = defaultLocale;
    dayjs.locale(defaultLocale.dayjsLocale);
  }

  isLocaleAvailable(locale: Locale) {
    return !!this.availableLocales.find(
      (availableLocale) => availableLocale.i18next === locale.i18next,
    );
  }

  @computed
  get locale(): Locale {
    return this.selectedLocale ? this.selectedLocale : this.fallbackLocale;
  }

  @action
  initialiseLocales(move: Move, i18nInstance: i18n) {
    const locales = move.locales.map(localeByBackend) as Locale[];
    const defaultLocale = localeByBackend(move.defaultLocale) as Locale;

    this.stores.localesStore.setAvailableLocales(locales);
    this.stores.localesStore.setDefaultLocale(defaultLocale);

    // check if we have selected locale in our local storage
    const selectedIsoLocale = localStorage.getItem(i18nextLngKey);
    if (selectedIsoLocale) {
      // find if that locale exists in i18n configuration and if it's allowed in move.locales
      const selectedLocale = localeByIso(selectedIsoLocale);
      if (selectedLocale && this.stores.localesStore.isLocaleAvailable(selectedLocale)) {
        this.stores.localesStore.setLocale(selectedLocale);
      } else {
        // locale in local storage either not found or not allowed. use the default one instead
        this.stores.localesStore.setLocale(defaultLocale);
        i18nInstance.changeLanguage((this.stores.localesStore.defaultLocale as Locale).i18next);
      }
    } else {
      // no locale selected, use the default one
      i18nInstance.changeLanguage(defaultLocale.i18next);
      this.stores.localesStore.setLocale(defaultLocale);
    }
  }
}

export { LocalesStore };
