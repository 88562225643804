import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import Collapse from "@kunukn/react-collapse";
import colors from "../../../utils/colors";
import EmployeeUiLayout from "../../../components-mobile/EmployeeUiLayout";
import ReturnButton from "../../../components-mobile/ReturnButton";
import { InjectedFloorPlanStore, FloorPlanStore } from "../../../stores/floorplan";
import { FAQuestion } from "../../../types/move";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Title, SubtitleWithoutMargins, Description } from "../../../components/BrandText";
import { onDesktop } from "../../../utils/breakpoint";
import { ApplicationStore } from "../../../stores/application";

const QuestionsContainer = styled.div`
  margin-top: 26px;
  width: 100%;
`;

const QuestionBtn = styled.button`
  width: 100%;
  height: 50px;
  display: block;
  background-color: ${(p: { isDark: boolean }) => (p.isDark ? colors.GREEN_DARK : colors.TP_GREEN)};
  border: 0px;
  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.p`
  color: ${colors.WHITE}
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const MissingValueText = styled.p`
  font-size: 14px;
  color: ${colors.DARK_GRAY};
  margin: 20px;
  margin-top: 10px;
  font-weight: 400;
`;

const CustomCollapse = styled(Collapse)`
  height: 70px;
  background-color: ${colors.WHITE};
`;

const CollapseContent = styled.div`
  font-size: 14px;
  color: ${colors.DARK_GRAY};
  padding: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5;
`;

const FAQContainer = styled.div`
  ${onDesktop} {
    display: none;
  }
`;

const Question = styled(SubtitleWithoutMargins)`
  margin: 32px 0 16px 0;
  display: none;
  ${onDesktop} {
    display: block;
  }
`;

const Answer = styled(Description)`
  display: none;
  ${onDesktop} {
    display: block;
  }
`;

type IProps = RouteComponentProps &
  WithTranslation & {
    floorPlanStore: FloorPlanStore;
    applicationStore: ApplicationStore;
  };

@inject("floorPlanStore")
@inject("applicationStore")
@observer
class Faq extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.renderQuestions = this.renderQuestions.bind(this);
    this.openQuestion = this.openQuestion.bind(this);
  }

  get injected() {
    return this.props as InjectedFloorPlanStore;
  }

  async componentDidMount() {
    if (this.injected.floorPlanStore.backendPassword !== "") {
      this.injected.floorPlanStore.fetchFAQ();
    }
  }

  openQuestion = (faq: FAQuestion) => {
    this.props.floorPlanStore.toggleFAQ(faq);
  };

  renderQuestions = (faqs: FAQuestion[]) =>
    faqs.map((faq, i) => (
      <div key={`${i}-faq-item`}>
        <FAQContainer>
          <QuestionBtn onClick={() => this.openQuestion(faq)} isDark={i % 2 === 0}>
            <ButtonText>{faq.question}</ButtonText>
          </QuestionBtn>
          <CustomCollapse isOpen={faq.isOpen}>
            <CollapseContent>{faq.answer}</CollapseContent>
          </CustomCollapse>
        </FAQContainer>
        <Question>{faq.question}</Question>
        <Answer>{faq.answer}</Answer>
      </div>
    ));

  render() {
    const { t, history } = this.props;
    const { faQuestions } = this.injected.floorPlanStore;
    const { headingColor } = this.props.applicationStore;
    return (
      <EmployeeUiLayout>
        <ReturnButton
          customColor={headingColor}
          history={history}
          to="/move/info"
          title={t("info.return_button_link")}
        />
        <Title style={{ color: headingColor }}>{t("faq.title")}</Title>
        <QuestionsContainer>
          {faQuestions && faQuestions.length > 0 ? (
            this.renderQuestions(faQuestions)
          ) : (
            <MissingValueText>{t("faq.no_questions")}</MissingValueText>
          )}
        </QuestionsContainer>
      </EmployeeUiLayout>
    );
  }
}

export default withTranslation()(Faq);
