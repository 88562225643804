import { commonTheme } from "@fmg-packages/common-components";
import { Theme } from "@material-ui/core/styles";

const relokatorMuiTheme: Theme = {
  ...commonTheme,
};

relokatorMuiTheme.typography.fontFamily = "Barlow";

export default relokatorMuiTheme;
