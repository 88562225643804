import React from "react";
import styled from "styled-components";
import { onDesktop } from "../../utils/breakpoint";

const ArticleEditorInnerHtml = styled.div`
  .grid {
    height: 100%;
  }
  
  h1 {
    font-size: 40px;
    line-height: 1.2;
  }

  h2 {
    font-size: 24px;
    line-height: 1.3;
  }

  h3 {
    font-size: 21px;
    line-height: 1.5;
  }

  h1,
  h2,
  h3 {
    font-family: inherit;
    font-weight: bold;
    font-style: normal;
    color: #000B1D;
    text-rendering: optimizeLegibility;
    letter-spacing: -0.01em;
  }

  figure + p {
    margin-top: 1rem;
  }

  figure + p + p {
    margin-top: 0.5rem;
    font-weight: lighter;
  }

  h1 + *, h2 + *, h3 + *, h4 + *, h5 + *, h6 + * {
    margin-top: 12px;
  }
  p + h2, ul + h2, ol + h2, blockquote + h2, figure + h2, pre + h2, table + h2, dl + h2, address + h2 {
    margin-top: 24px;
  }
  p + h3, ul + h3, ol + h3, blockquote + h3, figure + h3, pre + h3, table + h3, dl + h3, address + h3 {
    margin-top: 24px;
  }
  p + h4, ul + h4, ol + h4, blockquote + h4, figure + h4, pre + h4, table + h4, dl + h4, address + h4 {
    margin-top: 24px;
  }
  p + h5, ul + h5, ol + h5, blockquote + h5, figure + h5, pre + h5, table + h5, dl + h5, address + h5 {
    margin-top: 24px;
  }
  p + h6, ul + h6, ol + h6, blockquote + h6, figure + h6, pre + h6, table + h6, dl + h6, address + h6 {
    margin-top: 24px;
  }
  p + *, ul + *, ol + *, blockquote + *, figure + *, pre + *, table + *, dl + *, address + * {
    margin-top: 16px;
  }
  div + * {
    margin-top: 24px;
  }
  h1 + h2 {
    margin-top: 24px;
  }
  h2 + h3 {
    margin-top: 24px;
  }
  h3 + h4 {
    margin-top: 24px;
  }
  h4 + h5 {
    margin-top: 24px;
  }
  h5 + h6 {
    margin-top: 24px;
  }

  > .grid {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: repeat(4, 1fr);

    p,
    ul,
    h1,
    h2,
    h3 {
      padding: 0 2rem;
    }

    ul {
      margin: 0.5rem;
    }

    li {
      margin: 0 2rem;
    }

    ${onDesktop} {
      grid-template-columns: repeat(12, 1fr);

      p,
      ul,
      h1,
      h2,
      h3 {
        padding: 0rem;
      }
    }
  }

  .column {
    display: grid;

    h3:first-child {
      margin-top: 2rem;
    }

    p:last-child,
    ul:last-child {
      margin-bottom: 2rem;
    }
  }
  
  > .float-left {
    float: left;
    margin-right: 1em;
    margin-bottom: 1em;
    max-width: 200px;
  }

  > .float-right {
    float: right;
    margin-left: 1em;
    margin-bottom: 1em;
    max-width: 200px;
  }
  
  > .align-center {
    text-align: center;
  }
`;

type IProps = {
  articleHtml: string;
};

export const ArticleEditorWrapper = ({ articleHtml }: IProps) => {
  return (
    <ArticleEditorInnerHtml
      className="arx-editor arx-reset arx-content"
      dangerouslySetInnerHTML={{ __html: articleHtml }}
    />
  );
};
