import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import { Floor } from "../types/floorplan";

const TwinBtn = styled.button`
  height: 40px;
  width: 26px;
  border: 1px solid ${colors.TURQUOISE};
  background-color: ${(p: { isSelected?: boolean }) =>
    p.isSelected ? colors.TURQUOISE : "#edfaff"};
  color: ${(p: { isSelected?: boolean }) => (p.isSelected ? colors.WHITE : colors.TURQUOISE)};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  &:focus {
    outline: none;
  }
`;

const SingleBtn = styled(TwinBtn)`
  border-radius: 15px 15px 15px 15px;
`;

const LeftBtn = styled(TwinBtn)`
  border-radius: 15px 15px 0 0;
`;

const RightBtn = styled(TwinBtn)`
  border-radius: 0 0 15px 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IProps {
  floors: Floor[];
  onClick: (floor: Floor) => void;
  currentFloor: Floor;
}

const TwinButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { floors, onClick, currentFloor } = props;
  const firstFloor = floors[0];
  const lastFloor = floors[floors.length - 1];

  return (
    <Container>
      {floors.length === 1 && (
        <SingleBtn
          key={firstFloor.title}
          onClick={() => onClick(firstFloor)}
          isSelected={currentFloor === firstFloor}
        >
          {firstFloor.title}
        </SingleBtn>
      )}

      {floors.length === 2 && (
        <>
          <LeftBtn
            key={firstFloor.title}
            onClick={() => onClick(firstFloor)}
            isSelected={currentFloor === firstFloor}
          >
            {firstFloor.title}
          </LeftBtn>
          <RightBtn
            key={lastFloor.title}
            onClick={() => onClick(lastFloor)}
            isSelected={currentFloor === lastFloor}
          >
            {lastFloor.title}
          </RightBtn>
        </>
      )}

      {floors.length > 2 && (
        <>
          <LeftBtn
            key={firstFloor.title}
            onClick={() => onClick(firstFloor)}
            isSelected={currentFloor === firstFloor}
          >
            {firstFloor.title}
          </LeftBtn>

          {floors
            .slice(0, -1)
            .slice(1)
            .map((floor) => (
              <TwinBtn
                key={floor.title}
                onClick={() => onClick(floor)}
                isSelected={currentFloor === floor}
              >
                {floor.title}
              </TwinBtn>
            ))}

          <RightBtn
            key={lastFloor.title}
            onClick={() => onClick(lastFloor)}
            isSelected={currentFloor === lastFloor}
          >
            {lastFloor.title}
          </RightBtn>
        </>
      )}
    </Container>
  );
};
export default TwinButton;
