import React from "react";
import { RouteComponentProps } from "react-router";
import { MobXProviderContext, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import PeopleModal from "./PeopleModal";
import { PeopleStore } from "../stores/people";
import { Owner } from "../types/floorplan";
import { get } from "lodash";

type IProps = RouteComponentProps<any> & {
  onCloseGoTo: string;
  onClickGoTo?: string;
};

const SearchResultsModal: React.FunctionComponent<IProps> = observer((props: IProps) => {
  const { t } = useTranslation();
  const { peopleStore } = React.useContext<{ peopleStore: PeopleStore }>(MobXProviderContext);
  const people = peopleStore.searchResults;

  const getRightSideText = (person: Owner) =>
    [get(person, "space.title"), get(person, "table.letter"), " >"]
      .filter((s) => s && s.length > 0)
      .join("");

  const getOnPersonClickGoTo = (person: Owner) => {
    if (!person.floor || !person.floor.id) {
      return null;
    }
    if (!person.space || !person.space.id) {
      return `/move/floor/${person.floor.id}`;
    }
    return `/move/floor/${person.floor.id}/space-popup/${person.space.id}`;
  };

  return (
    <PeopleModal
      people={people}
      title={t("search.results.title")}
      subtitle={people.length > 0 ? t("search.results.hint") : ""}
      leftSideTitle={
        people.length > 0 ? t("search.results.people_matching") : t("search.results.no_results")
      }
      rightSideTitle={people.length > 0 ? t("search.results.space_and_table") : ""}
      getRightSideText={getRightSideText}
      getOnPersonClickGoTo={getOnPersonClickGoTo}
      popup={true}
      {...props}
    />
  );
});

export default SearchResultsModal;
