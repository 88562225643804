import StoresComponent from "./StoresComponent";
import axios from "axios";
import { Dayjs } from "dayjs";
import {
  apiURL,
  apiAxiosConfig,
  handleAsyncErrors,
  expect,
  simpleLazyObservable,
} from "../utils/api";
import { action } from "mobx";
import dayjs from "dayjs";
import { sortBy } from "lodash";

/*
  Types: Schedule-related
*/

type AScheduleEvent = {
  date_from: string;
  date_to?: string;
  description: string;
};

export type ScheduleEvent = {
  start: Dayjs;
  end?: Dayjs;
  description: string;
};

const parseScheduleEvent = (input: AScheduleEvent) => {
  return {
    start: dayjs(input.date_from, "DD-MM-YYYY").startOf("day"),
    end: input.date_to ? dayjs(input.date_to, "DD-MM-YYYY").endOf("day") : undefined,
    description: input.description,
  } as ScheduleEvent;
};

type ScheduleEndpointResponse = {
  events: AScheduleEvent[];
};

// ----------------------------------------------------------------------------

export class MoveStore {
  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  schedule = simpleLazyObservable<ScheduleEvent[]>(this, this.fetchSchedule, []);

  // --------------------------------------------------------------------------

  @handleAsyncErrors({ logErrorMessage: "Error in fetching move schedule" })
  @action
  private async fetchSchedule() {
    const unsortedEvents = expect(
      200,
      await axios.get<ScheduleEndpointResponse>(
        apiURL("/relokator/schedule"),
        apiAxiosConfig(this.stores),
      ),
    ).data.events.map(parseScheduleEvent);
    return sortBy(unsortedEvents, [
      (e) => e.start.unix(), // First sort by start date
      (e) => (e.end ? e.end.unix() : e.start.unix()), // and if those are same, try to sort by end date
    ]);
  }
}
