import React from "react";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
} from "react-router-dom";
import Schedule from "../Move/ScheduleView";
import FloorPlan from "../Move/FloorPlan";
import Contact from "../Move/Contact/Contact";
import InformationDashboard from "../Move/Information/InformationDashboard";
import Faq from "../Move/Contact/Faq";
import QuestionForm from "../Move/Contact/QuestionForm";
import FeedbackForm from "../Move/Contact/FeedbackForm";
import ErrorForm from "../Move/Contact/ErrorForm";
import Document from "../Move/Information/Document";
import SyncFloorNumberFromUrlToStore from "../../components-mobile/SyncFloorNumberFromUrlToStore";
import { MobXProviderContext } from "mobx-react";
import { FloorPlanStore } from "../../stores/floorplan";
import { get } from "lodash";
import SpaceAssignsModal from "../../components-mobile/SpaceAssignsModal";
import SearchBar from "../../components-mobile/SearchBar";
import SearchResultsModal from "../../components-mobile/SearchResultsModal";

const renderSearchBar = (forceClear: boolean, hide?: boolean) => (
  props: RouteComponentProps<any>,
) => <SearchBar forceClear={forceClear} hide={hide} {...props} />;

const MoveNav: React.FC = () => {
  const { floorPlanStore } = React.useContext<{ floorPlanStore: FloorPlanStore }>(
    MobXProviderContext,
  );
  const currentFloorId = get(floorPlanStore, "currentFloor.id");
  const { hideFloorSelection } = floorPlanStore;
  return (
    <Router>
      <Route exact path="/move/info" component={InformationDashboard} />
      <Route path="/move/info/doc/:docId" component={Document} />

      <Route path="/move/schedule" component={Schedule} />
      <Route path="/move/FAQ" component={Faq} />

      <Route exact path="/move/contact" component={Contact} />
      <Route path="/move/contact/error-report" component={ErrorForm} />
      <Route path="/move/contact/feedback" component={FeedbackForm} />
      <Route path="/move/contact/ask" component={QuestionForm} />

      <Route path="/move/floor/:floorIdStr" component={SyncFloorNumberFromUrlToStore} />

      <Switch>
        <Route path="/move/floor/:floorIdStr/space/:spaceIdStr" />
        <Route
          path={["/move/floor/:floorIdStr/search/:searchString", "/move/floor/:floorIdStr/search"]}
          render={renderSearchBar(false)}
        />
        <Route path="/move/floor/:floorIdStr" render={renderSearchBar(true, hideFloorSelection)} />
      </Switch>

      <Switch>
        <Route
          path="/move/floor/:floorIdStr/space/:spaceIdStr"
          render={(props: RouteComponentProps<any>) => (
            <SpaceAssignsModal
              onCloseGoTo={`/move/floor/${props.match.params.floorIdStr}`}
              popup={false}
              {...props}
            />
          )}
        />
        <Route path="/move/floor/:floorIdStr" component={FloorPlan} />
        <Route exact path="/move/floor">
          <Redirect to={`/move/floor/${currentFloorId}`} />
        </Route>
      </Switch>
      <Route
        path="/move/floor/:floorIdStr/space-popup/:spaceIdStr"
        render={(props: RouteComponentProps<any>) => (
          <SpaceAssignsModal
            onClickGoTo={`/move/floor/${props.match.params.floorIdStr}/space/${props.match.params.spaceIdStr}`}
            onCloseGoTo={`/move/floor/${props.match.params.floorIdStr}`}
            popup={true}
            {...props}
          />
        )}
      />
      <Route
        path="/move/floor/:floorIdStr/search/:searchString"
        render={(props: RouteComponentProps<any>) => (
          <SearchResultsModal
            onCloseGoTo={`/move/floor/${props.match.params.floorIdStr}`}
            {...props}
          />
        )}
      />
    </Router>
  );
};

export default MoveNav;
