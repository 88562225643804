import React, { Component } from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import { ContactInfo, GeneralInfo, isGeneralInfoArray, isContactInfo } from "../types/api";
import { InfoType } from "../types/move";
import { onDesktop } from "../utils/breakpoint";
import { observer, inject } from "mobx-react";
import { ApplicationStore, InjectedApplicationStore } from "../stores/application";
import { withTranslation, WithTranslation } from "react-i18next";

interface IInfoTitle {
  hasTopMargin?: boolean;
  customColor: string;
}
interface ColorProps {
  customColor: string;
}
const Card = styled.div<ColorProps>`
  min-width: 200px;
  padding: 26px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.customColor ? props.customColor : `${colors.TP_TURQUOISE_DARK}`};
  ${onDesktop} {
    background-color: ${(props) => (props.customColor ? props.customColor : `#d8f0f9`)};
    border-radius: 0;
    padding: 32px;
  }
`;

const InfoTitle = styled.p<IInfoTitle>`
  color: ${(props) => (props.customColor ? props.customColor : `${colors.BLUE}`)};
  font-size: 1.1em;
  font-weight: 500;
  text-transform: uppercase;

  ${(props) => props.hasTopMargin && "margin-top: 32px"}
`;

const InfoText = styled.p`
  margin-top: 4px;
  color: ${colors.DARK_GRAY};
`;
type IState = {};
type IProps = WithTranslation & {
  infoType: InfoType;
  infoData: GeneralInfo[] | ContactInfo;
  applicationStore?: ApplicationStore;
};

@inject("applicationStore")
@observer
class InfoCard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }
  get injected() {
    return this.props as InjectedApplicationStore;
  }
  render() {
    const { t } = this.props;
    const { primaryColor, darkerPrimaryColor } = this.injected.applicationStore;
    const { infoType, infoData } = this.props;
    const renderGeneralInfo = (infos: GeneralInfo[]) =>
      infos.map((info, idx) => (
        <div key={idx}>
          <InfoTitle customColor={darkerPrimaryColor} hasTopMargin={idx > 0}>
            {info.title}
          </InfoTitle>
          <InfoText>{info.content === "" ? "-" : info.content}</InfoText>
        </div>
      ));

    const renderContactInfo = (contactInfo: ContactInfo) => (
      <>
        <InfoTitle customColor={darkerPrimaryColor}>
          {contactInfo.job_title !== "" ? contactInfo.job_title : "Contact"}
        </InfoTitle>
        <InfoText>{contactInfo.name}</InfoText>
        <InfoTitle customColor={darkerPrimaryColor} hasTopMargin>
          {t("form.phone.label")}
        </InfoTitle>
        <InfoText>{contactInfo.phone}</InfoText>
        <InfoTitle customColor={darkerPrimaryColor} hasTopMargin>
          {t("form.email.label")}
        </InfoTitle>
        <InfoText>{contactInfo.email}</InfoText>
      </>
    );

    return (
      <Card customColor={primaryColor}>
        {infoType === InfoType.General &&
          isGeneralInfoArray(infoData) &&
          renderGeneralInfo(infoData)}
        {infoType === InfoType.Contact && isContactInfo(infoData) && renderContactInfo(infoData)}
      </Card>
    );
  }
}
export default withTranslation()(InfoCard);
