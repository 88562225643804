import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import {
  FloorLayout,
  FloorMarker,
  ContactInfo,
  FAQElement,
  Feedback,
  WritableMove,
  MoveApiResponse,
  LoginResponse,
  UploadedDocument,
  ColorResponse,
} from "../types/api";
import { Owner, Move } from "../types/floorplan";
import { PageDocumentWithTranslations } from "../types/move";
import { Stores } from "../stores/StoresComponent";
import { parseMove } from "./typeConversions";
import { get, isNil } from "lodash";
import { ILazyObservable, lazyObservable } from "mobx-utils";
import {
  TimelineSection,
  convertTimelineSectionsResponse,
  TimelineSectionRequest,
} from "@fmg-packages/common-components";

const backendBaseUrl = (subdomain: string) =>
  process.env.REACT_APP_BACKEND_HOST ? `//${process.env.REACT_APP_BACKEND_HOST}` : "";

const apiBaseUrl = (subdomain: string) => `${backendBaseUrl(subdomain)}/api/v1`;

const getApiHeaders = (password: string, subdomain: string) => ({
  Authorization: `Bearer ${password}`,
  "X-SUBDOMAIN": subdomain,
});

const API = {
  async getAllPages(password: string, subdomain: string) {
    const URL = `${apiBaseUrl(subdomain)}/relokator/pages`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get(URL, config);

    if (response.status === 200) {
      return get(response, "data.pages", []) as PageDocumentWithTranslations[];
    }
    throw new Error("Error getting move");
  },
  async getPage(password: string, subdomain: string, documentId: string) {
    const URL = `${apiBaseUrl(subdomain)}/relokator/pages/${documentId}`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get(URL, config);
    if (response.status === 200) {
      return get(response, "data", []) as PageDocumentWithTranslations;
    }
    throw new Error("Error getting move");
  },
  async postFeedback(password: string, subdomain: string, feedback: Feedback) {
    const URL = `${apiBaseUrl(subdomain)}/relokator/feedback`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.post(URL, feedback, config);
    if (response.status === 201) {
      return response;
    }
    throw new Error("Error creating new marker");
  },
  async getMove(password: string, subdomain: string): Promise<Move> {
    const URL = `${apiBaseUrl(subdomain)}/relokator/move`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get<MoveApiResponse>(URL, config);
    if (response.status === 200) {
      return parseMove(
        response.data,
        `${apiBaseUrl(subdomain)}/relokator/logo/${response.data.id}`,
      );
    }
    throw new Error("Error getting move");
  },
  async saveMove(password: string, subdomain: string, move: WritableMove) {
    const URL = `${apiBaseUrl(subdomain)}/move.json`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.put(URL, move, config);
    if (response.status === 202) {
      return response;
    }
    throw new Error("Error saving move options");
  },
  async getUnassignedOwners(password: string, subdomain: string): Promise<string[]> {
    const URL = `${apiBaseUrl(subdomain)}/move/unassigned-people.json`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get(URL, config);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Error getting unassigned owners");
  },
  async saveUnassignedOwners(password: string, subdomain: string, owners: Owner[]) {
    const URL = `${apiBaseUrl(subdomain)}/move.json`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const data: WritableMove = {
      unassignedPeople: owners.map((o) => o.name),
    };
    const response = await axios.put(URL, data, config);
    if (response.status === 202) {
      return response;
    }
    throw new Error("Error saving unassigned owners");
  },
  async getContacts(password: string, subdomain: string): Promise<ContactInfo[]> {
    const URL = `${apiBaseUrl(subdomain)}/relokator/contacts`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get(URL, config);
    if (response.status === 200) {
      return get(response, "data.contacts", []) as ContactInfo[];
    }
    throw new Error("Error getting Contacts");
  },
  async getFAQ(password: string, subdomain: string): Promise<FAQElement[]> {
    const URL = `${apiBaseUrl(subdomain)}/relokator/faqs`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get(URL, config);
    if (response.status === 200) {
      return get(response, "data.questions", []) as FAQElement[];
    }
    throw new Error("Error getting FAQ");
  },

  async getAllLayouts(password: string, subdomain: string): Promise<FloorLayout[]> {
    const URL = `${apiBaseUrl(subdomain)}/relokator/layouts`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };

    const response = await axios.get(URL, config);
    return get(response, "data.layouts", []) as FloorLayout[];
  },
  async getLayoutData(password: string, subdomain: string, id: string) {
    const URL = `${apiBaseUrl(subdomain)}/relokator/layouts/${id}`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get(URL, config);
    if (response.status === 200) {
      return get(response, "data", []) as FloorLayout;
    }
    throw new Error("Error getting all layouts");
  },
  async postNewLayout(
    password: string,
    subdomain: string,
    floorName: string,
    file: File,
    labelColor: string | null,
    floorSize: string,
  ) {
    const URL = `${apiBaseUrl(subdomain)}/layouts.json`;
    const data = new FormData();
    // This format is what rails backend assumes
    data.append("move_layout[floor_name]", floorName);
    data.append("move_layout[layout]", file);
    if (labelColor) {
      data.append("move_layout[label_color]", labelColor);
    }
    data.append("move_layout[floor_size]", floorSize);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        ...getApiHeaders(password, subdomain),
      },
    };
    const response = await axios.post(URL, data, config);
    if (response.status === 201) {
      return response;
    }
    throw new Error("Error saving new layout");
  },

  async deleteLayout(password: string, subdomain: string, id: string) {
    const URL = `${apiBaseUrl(subdomain)}/layouts/${id}`;

    const config = {
      headers: { ...getApiHeaders(password, subdomain) },
    };

    await axios.delete(URL, config);
  },
  async postNewMarker(password: string, subdomain: string, marker: FloorMarker) {
    const URL = `${apiBaseUrl(subdomain)}/markers.json`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.post(URL, marker, config);
    if (response.status === 200) {
      return response;
    }
    throw new Error("Error creating new marker");
  },
  async removeMarker(password: string, subdomain: string, tagId: string) {
    const URL = `${apiBaseUrl(subdomain)}/markers/${tagId}.json`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.delete(URL, config);
    if (response.status === 200) {
      return response;
    }
    throw new Error("Error removing marker");
  },

  async updateMarker(password: string, subdomain: string, marker: FloorMarker) {
    if (!isNil(marker.id)) {
      const URL = `${apiBaseUrl(subdomain)}/markers/${marker.id}.json`;
      const config = {
        headers: getApiHeaders(password, subdomain),
      };
      const response = await axios.put(URL, marker, config);
      if (response.status === 200) {
        return response;
      }
      throw new Error("Error updating marker");
    }
  },
  // Marker id is the marker where owner is saved (SpaceTag or TableTag)
  // Owner name is used to create a new user to marker in database.
  // It creates an id for that user and returns it in response
  async saveNewOwner(password: string, subdomain: string, markerId: string, ownerName: string) {
    const URL = `${apiBaseUrl(subdomain)}/marker-users.json`;
    const user = {
      marker: {
        moveLayoutMarkerId: markerId,
        name: ownerName,
      },
    };
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.post(URL, user, config);
    if (response.status === 201) {
      return response;
    }
    if (response.status === 422) {
      throw new Error("Invalid request");
    }
    throw new Error("Error creating new marker user");
  },
  async deleteMarkerOwner(password: string, subdomain: string, id: string | undefined) {
    if (!isNil(id)) {
      const URL = `${apiBaseUrl(subdomain)}/marker-users/${id}.json`;
      const config = {
        headers: getApiHeaders(password, subdomain),
      };
      const response = await axios.delete(URL, config);

      if (response.status === 202) {
        return response;
      }
      throw new Error("Error deleting marker user");
    }
  },
  async updateMarkerOwner(password: string, subdomain: string, owner: Owner) {
    if (!isNil(owner.id)) {
      const URL = `${apiBaseUrl(subdomain)}/marker-users/${owner.id}.json`;

      let spaceId: string | undefined;
      if (owner.table && owner.table.id) {
        spaceId = owner.table.id;
      } else if (owner.space && owner.space.id) {
        spaceId = owner.space.id;
      }

      const user = {
        marker: {
          name: owner.name,
          moveLayoutMarkerId: spaceId,
          stickersNumber: owner.stickersNumber,
        },
      };
      const config = {
        headers: getApiHeaders(password, subdomain),
      };
      const response = await axios.put(URL, user, config);

      if (response.status === 200) {
        return response;
      }
      throw new Error("Error updating marker user");
    }
  },
  async fetchTimelineSections(password: string, subdomain: string): Promise<TimelineSection[]> {
    const URL = `${apiBaseUrl(subdomain)}/relokator/timeline`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const { data } = await axios.get<{ sections: TimelineSectionRequest[] }>(
      URL,
      config as AxiosRequestConfig,
    );
    return data.sections.map(convertTimelineSectionsResponse);
  },
  async requestLoginLink(password: string, subdomain: string, email: string) {
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    try {
      return get(
        await axios.post(apiURL("/relokator/link"), { email: email }, config),
        "data",
        false,
      ) as boolean;
    } catch (e) {
      return false;
    }
  },
  async login(token: string, password: string, subdomain: string): Promise<LoginResponse> {
    const config = {
      headers: getApiHeaders(password, subdomain),
    };

    const result = await axios.post(apiURL("/relokator/login"), { token: token }, config);
    return get(result, "data", []) as LoginResponse;
  },
  async getUploadedDocuments(password: string, subdomain: string) {
    const URL = `${apiBaseUrl(subdomain)}/relokator/documents`;
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get<UploadedDocument>(URL, config);
    if (response.status === 200) {
      return get(response, "data", []) as UploadedDocument[];
    }

    throw new Error("Error getting documents");
  },
  async downloadDocument(password: string, subdomain: string, documentId: string) {
    const URL = `${apiBaseUrl(subdomain)}/relokator/document/${documentId}`;
    const config = {
      headers: getApiHeaders(password, subdomain),
      responseType: "blob" as ResponseType,
    };
    const response = await axios.get(URL, config);
    if (response.status === 200) {
      return get(response, "data");
    }

    throw new Error("Error getting documents");
  },

  async getColors(password: string, subdomain: string, moveId: string): Promise<ColorResponse> {
    const config = {
      headers: getApiHeaders(password, subdomain),
    };
    const response = await axios.get(apiURL(`/relokator/colors/${moveId}`), config);
    if (response.status === 200) {
      return get(response, "data") as ColorResponse;
    }
    throw new Error("Error getting colors");
  },

  getMoveBackgroundUrl(subdomain: string) {
    return `${apiBaseUrl(subdomain)}/relokator/background/${subdomain}`;
  },
};

/*
  Helpers
*/

export const backendURL = (path: string) => backendBaseUrl("") + path;
export const apiURL = (path: string) => apiBaseUrl("") + path;

export const apiHeaders = (
  sc: Stores,
  customHeaders: Record<string, unknown> = {},
  customPassword?: string,
) => {
  const moveID = sc.floorPlanStore.APISubdomain;
  const password = customPassword ? customPassword : sc.floorPlanStore.backendPassword;
  return {
    ...customHeaders,
    ...getApiHeaders(password, moveID),
  };
};

export const apiAxiosConfig = (
  sc: Stores,
  customHeaders: Record<string, unknown> = {},
  customPassword?: string,
): AxiosRequestConfig => {
  return {
    headers: apiHeaders(sc, customHeaders, customPassword),
  };
};

/*
  Decorators
*/

type HandleAsyncErrorsOption = {
  logErrorMessage?: string;
  alertErrorMessage?: string;
};

// Adapted from https://gist.github.com/remojansen/16c661a7afd68e22ac6e
// and https://stackoverflow.com/a/45021102
export const handleAsyncErrors = <T>(options: HandleAsyncErrorsOption) => {
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: unknown[]) {
      try {
        return await originalMethod.apply(this, args);
      } catch (error) {
        // @todo: Implement correct error handling
        if (options.logErrorMessage) {
          console.error(options.logErrorMessage);
        } else if (options.alertErrorMessage) {
          window.alert(options.alertErrorMessage);
        }
        console.error(error.message);
      }
    };
    return descriptor;
  };
};

export function expect<T>(expectedHttpStatus: number, response: AxiosResponse<T>) {
  if (response.status !== expectedHttpStatus) {
    throw new Error(`Expected HTTP ${expectedHttpStatus}, but got HTTP ${response.status} instead`);
  }
  return response;
}

export function simpleLazyObservable<T>(
  context: unknown,
  fetcher: () => Promise<T>,
  initialValue: T,
): ILazyObservable<T> {
  return lazyObservable<T>((sink) => {
    fetcher
      .bind(context)()
      .then((val) => {
        sink(val);
      });
  }, initialValue);
}

export default API;
