import StoresComponent from "./StoresComponent";
import { observable, computed, action, autorun } from "mobx";
import { defer } from "lodash";
import qs from "qs";
import API from "../utils/api";
import { LoginResult } from "../types/api";
import { Move } from "../types/floorplan";

export enum ApplicationMode {
  Admin = "ADMIN_UI_MODE",
  Employee = "EMPLOYEE_UI_MODE",
}

const LOCAL_STORAGE_PASSWORD_KEY = "password";

const LOCAL_STORAGE_PASSWORD_OBTAIN_TOKEN_KEY = "password_obtain_token";
export interface InjectedApplicationStore {
  applicationStore: ApplicationStore;
}
export class ApplicationStore {
  private storesComponent: StoresComponent | undefined;
  constructor(sc: StoresComponent) {
    this.storesComponent = sc;
    defer(this.initializeSideEffects);
  }

  get stores() {
    return this.storesComponent!.stores;
  }

  // --------------------------------------------------------------------------

  /*
    As this store (and almost the whole application) can be used in multiple
    different modes: floorplanner injected as a part of the Admin UI, or
    just employee data viewed via the Employee UI, it is sometimes important
    to know in which mode we are. Different parts of the application require
    different kind of data, and having applicationStore.mode available, we
    are able to drop some API calls which are not necessary.
  */
  @observable
  public isLoading = true;

  @observable
  public mode = ApplicationMode.Employee;

  @observable
  public subdomain = process.env.REACT_APP_SUBDOMAIN
    ? process.env.REACT_APP_SUBDOMAIN
    : window.location.host.split(".")[0];

  @computed
  get subdomainHasBeenSet() {
    /*
      "admin" is not a valid value for a subdomain, but because of timing issues,
      it might be that some API calls would get triggered before the actual subdomain
      information is read from the admin UI injection HTML attributes. This check
      prevents those calls from going through.
    */
    return this.subdomain !== undefined && this.subdomain !== "admin";
  }

  @observable
  public password = window.localStorage.getItem(LOCAL_STORAGE_PASSWORD_KEY) || "";

  @observable
  public primaryColor = "";

  @observable
  public darkerPrimaryColor = "";

  @observable
  public secondaryColor = "";

  @observable
  public darkerSecondaryColor = "";

  @observable
  public headingColor = "";

  @observable
  public backgroundColor = "";

  @observable
  public passwordObtainToken =
    window.localStorage.getItem(LOCAL_STORAGE_PASSWORD_OBTAIN_TOKEN_KEY) || "";

  @computed
  get backgroundUrl() {
    return API.getMoveBackgroundUrl(this.subdomain);
  }

  @computed
  get isAuthorized() {
    /*
      Admin mode does not necessarily need to have a password set, because it uses
      the normal session cookie to authenticate the user, instead of the
      Authorization header. We can assume that (at least in production) floorplanner
      is only loaded inside of the Admin UI, so we can assume that at that point
      the cookie already exists. For local development we can just visit the /login
      route before accessing the /floorplan/cpm route.
    */
    return (
      this.subdomainHasBeenSet && (this.mode === ApplicationMode.Admin || this.password !== "")
    );
  }

  // --------------------------------------------------------------------------

  private initializeSideEffects = () => {
    autorun(() => {
      if (!this.isAuthorized) {
        this.isLoading = false;
      }
      this.initializeLegacyFloorPlanStoreThings();
    });
  };

  // --------------------------------------------------------------------------

  @action
  public async setPassword(password: string) {
    await this.saveInStorage(LOCAL_STORAGE_PASSWORD_KEY, password);
    this.password = password;
  }

  @action
  public async getColors(move: Move) {
    const colors = await API.getColors(this.password, this.subdomain, move.id);
    if (colors) {
      this.primaryColor = colors.primaryColor;
      this.darkerPrimaryColor = colors.darkerPrimaryColor;
      this.secondaryColor = colors.secondaryColor;
      this.darkerSecondaryColor = colors.darkerSecondaryColor;
      this.backgroundColor = colors.backgroundColor;
      this.headingColor = colors.headingColor;
    }
  }

  public async requestLoginLink(email: string, password: string): Promise<boolean> {
    return await API.requestLoginLink(password, this.subdomain, email);
  }

  @action setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  public async clearPassword() {
    window.localStorage.removeItem(LOCAL_STORAGE_PASSWORD_KEY);
    this.password = "";
  }

  @action
  public enableAdminMode(subdomain?: string) {
    this.mode = ApplicationMode.Admin;
    if (subdomain) {
      this.subdomain = subdomain;
    }
    this.initializeLegacyFloorPlanStoreThings();
  }

  @action
  public async updatePasswordToken(search: string) {
    const parsedSearch = qs.parse(search.replace(/\?/g, ""));
    const token = parsedSearch["passwordToken"] as string;

    if (token) {
      await this.saveInStorage(LOCAL_STORAGE_PASSWORD_OBTAIN_TOKEN_KEY, token);
      this.passwordObtainToken = token;
    }
  }

  @action
  public async saveInStorage(key: string, value: string) {
    // Storing in local storage may be not enough as Chrome sometimes doesn't persist changes
    // See https://stackoverflow.com/questions/21139572/storing-a-variable-in-localstorage-is-too-slow
    // We're looping for some time on a best effort besis
    window.localStorage.setItem(key, value);

    do {
      await new Promise((resolve) => setTimeout(resolve, 50));
    } while (window.localStorage.getItem(key) !== value);
  }

  async login(): Promise<LoginResult> {
    try {
      const loginResponse = await API.login(
        this.passwordObtainToken,
        this.password,
        this.subdomain,
      );
      switch (loginResponse.result) {
        case LoginResult.ReloginNeededWithPassword:
        case LoginResult.ReloginNeededWithEmailAndPassword:
        case LoginResult.ReloginNeededWithEmail:
          break;
        case LoginResult.NewPassword:
          await this.setPassword(loginResponse.newPassword!);
          break;
        case LoginResult.LoggedIn:
          this.isLoading = true;
          break;
      }

      return loginResponse.result;
    } catch (e) {
      return LoginResult.ReloginNeededWithEmailAndPassword;
    }
  }

  // --------------------------------------------------------------------------

  @action
  public async initializeLegacyFloorPlanStoreThings() {
    if (this.isAuthorized) {
      if (!(await this.stores.floorPlanStore.fetchMove())) {
        this.isLoading = false;
      }
      await this.stores.floorPlanStore.refreshLayouts();
      if (this.stores.floorPlanStore.move) {
        await this.getColors(this.stores.floorPlanStore.move);
      }
      this.isLoading = false;
    }
  }
}
