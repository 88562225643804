import React from "react";
import styled from "styled-components";
import { FiMinus, FiPlus } from "react-icons/fi";
import colors from "../utils/colors";
import constants from "../utils/constants";

interface IProps {
  zoomLevel: number;
  onZoom: (level: number) => void;
  onMobile?: boolean;
}

interface ZoomButtonStyleProps {
  isMinZoomLevel: boolean;
  mobile?: boolean;
}

const ZoomButton = styled.button<ZoomButtonStyleProps>`
  background-color: ${(props) =>
    props.isMinZoomLevel
      ? props.theme.supportingColorLight || colors.BG_GRAY_LIGHT
      : props.theme.primaryColorLighter || colors.BG_BLUE_LIGHT};

  border-color: ${(props) =>
    props.isMinZoomLevel ? colors.GRAY : props.theme.primaryColor || colors.TURQUOISE};
    
  color: ${(props) =>
    props.isMinZoomLevel ? colors.GRAY : props.theme.primaryColor || colors.TURQUOISE}

  float: right;
  height: ${(p: { mobile?: boolean }) => (p.mobile ? "26px" : "28px")};
  width: ${(p: { mobile?: boolean }) => (p.mobile ? "40px" : "48px")};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  padding: 4px 0 0 0;

  &:focus {
    outline: none;
  }

  &:active {
    ${(props) => props.isMinZoomLevel && `border: 1px solid ${colors.GRAY};`}
  }
`;

const Zoom: React.FunctionComponent<IProps> = (props: IProps) => {
  const { zoomLevel, onZoom, onMobile } = props;

  const zoomIn = () => {
    return onZoom(zoomLevel + constants.ZOOM_LEVEL_INCREMENT);
  };

  const zoomOut = () => {
    return onZoom(Math.max(zoomLevel - constants.ZOOM_LEVEL_INCREMENT, constants.ZOOM_LEVEL_MIN));
  };

  return (
    <div>
      <ZoomButton
        style={{
          borderBottomRightRadius: 15,
          borderTopRightRadius: 15,
        }}
        onClick={zoomIn}
        mobile={onMobile}
        isMinZoomLevel={false}
      >
        <FiPlus size={15} />
      </ZoomButton>
      <ZoomButton
        style={{
          borderBottomLeftRadius: 15,
          borderTopLeftRadius: 15,
        }}
        onClick={zoomOut}
        mobile={onMobile}
        isMinZoomLevel={zoomLevel === constants.ZOOM_LEVEL_MIN}
      >
        <FiMinus size={15} />
      </ZoomButton>
    </div>
  );
};
export default Zoom;
