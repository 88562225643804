import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../utils/colors";
import { last } from "lodash";
import TextBlockLink from "./TextBlockLink";
import { onDesktop } from "../utils/breakpoint";

interface IProps {
  title: string;
  image: string;
  column: number;
  span: number;
  route: string;
  appearance: "image" | "text";
  customSecondaryColor: string;
  customDarkerSecondaryColor: string;
}
interface ButtonOverlayProps {
  customColor: string;
}

const fullWidthHeight = `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const roundedCornersOnDesktop = `
  ${onDesktop} {
    border-radius: 10px;
  }
`;

const ButtonContainer = styled.div<IProps>`
  grid-column: ${(props) => props.column} / span ${(props) => props.span};

  position: relative;

  // Hack for creating full square ContactButtons to the grid.
  // Adapted from https://jsfiddle.net/yvoas4wx/2/
  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &:first-child::before {
    padding-top: 50%;
  }

  ${onDesktop} {
    &::before {
      padding-top: 44%;
    }
  }
  ${roundedCornersOnDesktop}
`;

const ButtonImage = styled.img`
  ${fullWidthHeight}
  object-fit: cover;
  ${roundedCornersOnDesktop}

  // The images are squared but the container on desktop isn't
  // That's why we have to move the image position a bit to the bottom 
  ${onDesktop} {
    object-position: 50% 35%;
  }
`;

const ButtonOverlay = styled.div<ButtonOverlayProps>`
  ${fullWidthHeight}
  background-color: ${(props) => (props.customColor ? props.customColor : `${colors.GREEN}`)};
  opacity: 0.6;
  -moz-opacity: 60%;
  -webkit-opacity: 60%;
  z-index: 2;
  ${roundedCornersOnDesktop}
`;

const ButtonTitle = styled.div<ButtonOverlayProps>`
  position: absolute;
  top: 12px;
  left: 18px;
  right: 18px;
  bottom: 12px;
  z-index: 3;
  color: ${(props) => (props.customColor ? `${colors.BLACK}` : `${colors.WHITE}`)};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
`;

const WIDTHS = [160, 320, 640];

const filenameToSrcWithWp = (filename: string, size: number) =>
  filename.replace(".jpeg", `-${size}.jpeg ${size}w`);
const filenameToSrc = (filename: string, size: number) =>
  filename.replace(".jpeg", `-${size}.jpeg`);

const responsiveImageFor = (filename: string) => {
  const srcset = WIDTHS.map((w) => filenameToSrcWithWp(filename, w));
  const sizes = WIDTHS.slice(0, -1)
    .map((w) => `(max-width: ${w * 2}px) ${w}px`)
    .concat([`${last(WIDTHS)}px`]);
  const src = filenameToSrc(filename, Math.min(...WIDTHS));
  return <ButtonImage src={src} srcSet={srcset.join(", ")} sizes={sizes.join(", ")} />;
};

const ContactButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    title,
    image,
    route,
    appearance,
    customSecondaryColor,
    customDarkerSecondaryColor,
  } = props;
  const filename = "/contact-images/" + image;

  if (appearance === "text") {
    return (
      <TextBlockLink
        customcolor={customSecondaryColor}
        customdarkercolor={customDarkerSecondaryColor}
        to={route}
        text={title}
        color="blue"
      />
    );
  }

  return (
    <ButtonContainer {...props}>
      <Link to={route}>
        {responsiveImageFor(filename)}
        <ButtonOverlay customColor={customSecondaryColor} />
        <ButtonTitle customColor={customSecondaryColor}>{title}</ButtonTitle>
      </Link>
    </ButtonContainer>
  );
};
export default ContactButton;
