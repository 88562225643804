import React from "react";
import styled from "styled-components";
import NavButton from "./NavButton";
import { MobXProviderContext, observer } from "mobx-react";
import { FloorPlanStore } from "../stores/floorplan";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { ApplicationStore } from "../stores/application";

const Container = styled.div`
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-iterms: stretch;
`;

const MainNavigationElements: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const { floorPlanStore, applicationStore } = React.useContext<{
    floorPlanStore: FloorPlanStore;
    applicationStore: ApplicationStore;
  }>(MobXProviderContext);
  const currentFloorId = get(floorPlanStore, "currentFloor.id");
  const { headingColor } = applicationStore;
  const navButtons = [
    {
      title: t("info.nav_link"),
      icon: "info",
      redirect: "/move/info",
      showOnDesktop: true,
    },
    {
      title: t("schedule.nav_link"),
      icon: "clock",
      redirect: "/move/schedule",
      showOnDesktop: false,
    },
    {
      title: t("floor_plan.nav_link"),
      icon: "floor",
      redirect: "/move/floor/" + currentFloorId,
      showOnDesktop: true,
    },
    {
      title: t("contact.nav_link"),
      icon: "msg",
      redirect: "/move/contact",
      showOnDesktop: true,
    },
  ];

  return (
    <Container>
      {navButtons.map((btn) => {
        return (
          <NavButton
            customColor={headingColor}
            key={btn.redirect}
            title={btn.title}
            icon={btn.icon}
            showOnDesktop={btn.showOnDesktop}
            redirect={btn.redirect}
            active={window.location.pathname.indexOf(btn.redirect) === 0}
          />
        );
      })}
    </Container>
  );
});
export default MainNavigationElements;
